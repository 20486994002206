import React from "react";
import filter from "../../../assets/images/filter.svg";

const ProjectNameHeader = (options, frozenCoulmns, sortData, projectNameOnClick, selectedFields) => {
  let splittedCol = options?.split("_").join(" ");
  const isFilterActivated =
    (selectedFields && Object.keys(selectedFields)?.length && selectedFields[options]?.length>0);

  return (
    <div className="flex-display">
      <>
        <img
          src={filter}
          alt="Column Filter"
          className={
            ( isFilterActivated ||(frozenCoulmns.length>0 && frozenCoulmns?.includes(options)) || sortData?.includes(options)) ? "columnFilterIcon filter-color-change" : "columnFilterIcon"
          }
          onClick={(e) => projectNameOnClick(e, options)}
        />
        <span className={(isFilterActivated || sortData?.includes(options))  && "filter-color-change"}>
          {splittedCol === "Estimated AW Printer" && "Estimated AW@Printer"}
          {splittedCol === "Artwork Category" && "Category"}
          {splittedCol === "Project region" && "Region"}
          {splittedCol === "Artwork Brand" && "Brand"}
          {splittedCol === "Project Scale" && "Scale"}
          {splittedCol === "Company" && "FA Supplier"}
          {splittedCol === "Buffer To Work" && "B:W"}
          {splittedCol === "Artwork SMO" && "SMO"}
          {splittedCol !== "Estimated AW Printer" &&
            splittedCol !== "Artwork Category" &&
            splittedCol !== "Buffer To Work" &&
            splittedCol !== "Project region" &&
            splittedCol !== "Artwork Brand" &&
            splittedCol !== "Project Scale" &&
            splittedCol !== "Artwork SMO" &&
            splittedCol !== "Company" &&
            splittedCol}
        </span>
      </>
    </div>
  );
};

export default ProjectNameHeader;
import "./index.scss";
import { generateDropdownOptionList } from "../../../utils";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import filter from "../../../assets/images/filter.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { extractData } from "../../Projects/ProjectPlan/util";
import { AcpCommonService } from "../../../service/AcpCommonService";
import BlueFilterIcon from "../../../assets/images/BlueFilterIcon.svg";
import { SortAndFilterService } from "../../../service/SortAndFilterService";
import { Freeze, Frozen, SortAtoZ, clearColumnfilter, sortZtoA } from "../constant";
import { cicOptions } from "../../ArtworkStatus/utils";

const ACPFilter = ({
  op,
  onSort,
  filters,
  pageName,
  pegadata,
  sortData,
  splittedCol,
  projectData,
  isTreeTable,
  saveSettings,
  ProjectFrozen,
  frozenCoulmns,
  setTableRender,
  selectedFields,
  setProjectFrozen,
  resetMultiFilter,
  addFrozenColumns,
  projectColumnName,
  selectedColumnName,
  setReorderedColumn,
  onGlobalFilterChange,
  clearColumnWiseFilter,
  setProjectColumnNames,
  generateCustomOptions,
  acpBookingFrozenColumn,
  selectAllColumnNotIncluded,
}) => {
  const [optionListFinal, setOptionListFinal] = useState([]);
  const [columnWiseSelectedFields, setColumnWiseSelectedFields] = useState([]);
  const isFilterActivated =
    (pageName!=="dependancyMapping" && frozenCoulmns &&
      frozenCoulmns.length >= (selectAllColumnNotIncluded ? 1 : 2) &&
      frozenCoulmns?.includes(selectedColumnName)) ||
    (selectedFields &&
      Object.keys(selectedFields)?.includes(selectedColumnName) &&
      selectedFields[selectedColumnName]?.length > 0) ||
    (sortData && sortData.length && sortData?.includes(selectedColumnName));

  let dateFormattedColNames = [
    "Estimated_AW_Printer",
    "Estimated_SOP",
    "Estimated_AW_Readiness",
    "Estimated_SOS",
  ];

  useEffect(() => {
    if (resetMultiFilter) {
      setColumnWiseSelectedFields([]);
    }
  }, [resetMultiFilter]);

  useEffect(() => {
    if (selectedFields && selectedColumnName) {
      const _selectedFieldsRefWithoutEmptyArray =
        AcpCommonService.removeEmptyArraysWithNoData(selectedFields);
      setColumnWiseSelectedFields(_selectedFieldsRefWithoutEmptyArray[selectedColumnName]);
      let optionListModified = {};
      let finalFilter = [...(projectData?.length ? projectData : [])];
      if (isTreeTable) {
        const updatedObject = SortAndFilterService.removeKeyByColumn(
          selectedFields,
          selectedColumnName
        );
        let { Task, Filename, ...newObj } = updatedObject;
        let filteredData2 = [];
        let filteredData = [];
        if (Task?.length) {
          const Task2 = {
            Task: Task,
          };
          filteredData = SortAndFilterService.applyMutilabelFilter(pegadata, Task2);
        } else if (Filename?.length) {
          const Filename2 = {
            Filename: Filename,
          };
          filteredData = SortAndFilterService.applyMutilabelFilter(pegadata, Filename2);
        }
        if (Object.entries(newObj)?.length) {
          filteredData2 = SortAndFilterService.multipleColumnFilter(
            filteredData?.length ? filteredData : pegadata,
            newObj
          );
        }
        const finalFilteredData = filteredData2?.length
          ? filteredData2
          : filteredData?.length
          ? filteredData
          : pegadata;
        const flatPegadata = finalFilteredData?.flatMap((obj) => extractData(obj));
        if (isTreeTable && generateCustomOptions) {
          const updatedProjectData = SortAndFilterService.updateOwnerWithAssignee(projectData);
          const updatedFlatPegadata = SortAndFilterService.updateOwnerWithAssignee(flatPegadata);
          optionListModified = generateDropdownOptionList(
            AcpCommonService.isFieldBlankOrSingle(
              _selectedFieldsRefWithoutEmptyArray,
              selectedColumnName
            ) || Object.keys(_selectedFieldsRefWithoutEmptyArray)?.[0] === selectedColumnName
              ? updatedProjectData
              : updatedFlatPegadata,
            selectedColumnName,
            isTreeTable
          );
        } else if (isTreeTable && !generateCustomOptions) {
          optionListModified = generateDropdownOptionList(
            AcpCommonService.isFieldBlankOrSingle(
              _selectedFieldsRefWithoutEmptyArray,
              selectedColumnName
            ) || Object.keys(_selectedFieldsRefWithoutEmptyArray)?.[0] === selectedColumnName
              ? projectData
              : flatPegadata,
            selectedColumnName,
            isTreeTable
          );
        }
      } else {
        for (const [key, value] of Object.entries(_selectedFieldsRefWithoutEmptyArray)) {
          if (key !== selectedColumnName) {
            finalFilter = finalFilter?.filter((el) => {
              return value?.includes(el[key]);
            });
          }
        }
        optionListModified = generateDropdownOptionList(
          AcpCommonService.isFieldBlankOrSingle(
            _selectedFieldsRefWithoutEmptyArray,
            selectedColumnName
          ) || Object.keys(_selectedFieldsRefWithoutEmptyArray)?.[0] === selectedColumnName
            ? projectData
            : finalFilter,
          selectedColumnName,
          isTreeTable
        );
      }
      let sortedDates = optionListModified;
      if (
        (pageName === "ProjectPlanLists" &&
          (selectedColumnName === "Start_Date")) ||
        (pageName === "dashboard" &&
          (selectedColumnName === "Date" ||
            selectedColumnName === "AwRedinessDate" ||
            selectedColumnName === "ArtPrinterDate")) ||
        (pageName === "booking" &&
          (selectedColumnName === "EndDate" ||
            selectedColumnName === "StartDate" ||
            selectedColumnName === "ArtPrinterDate")) ||
        (pageName === "planning" &&
          (selectedColumnName === "StartDate" || selectedColumnName === "NewStartDate"))
      ) {
        sortedDates = AcpCommonService.sortDates(optionListModified);
      }
      setOptionListFinal(sortedDates);
    }
  }, [selectedFields, selectedColumnName]);

  const mutilSelectTemplate = (option) => {
    // return <div>{dateFormattedColNames?.includes(selectedColumnName) ? option : option}</div>;
    if (pageName === "artworkStatus") {
      return <div>{cicOptions(option)}</div>;
    } else {
      return <div>{option}</div>;
    }
  };

  const handleToggleFreeze = () => {
    const tempSelectedColumn = selectedColumnName;
    localStorage.setItem("BookingFreezedColumnClicked", tempSelectedColumn);
    addFrozenColumns(selectedColumnName);
    setProjectFrozen(!ProjectFrozen);
    saveSettings();
    projectColumnName &&
      acpBookingFrozenColumn(
        frozenCoulmns,
        setReorderedColumn,
        setProjectColumnNames,
        setTableRender,
        op,
        projectColumnName,
        pageName,
        selectedFields,
        "bookings"
      );
  };

  const confirmPopData = () => {
    return (
      <>
        {selectedColumnName?.length && selectedColumnName !== "SelectAll" ? (
          <div className="acp-filters">
            <div className="clearAllFilterMainDiv">
              <div
                id="clearAllFilter"
                className={isFilterActivated ? "clearAllFilter" : ""}
                onClick={() => (isFilterActivated ? clearColumnWiseFilter() : null)}
              >
                {clearColumnfilter}
              </div>
              <div className="clearAllFilterDiv">
                {isFilterActivated ? (
                  <img
                    alt="filter logo"
                    src={BlueFilterIcon}
                    className="header-icons"
                    onClick={() => clearColumnWiseFilter()}
                  />
                ) : (
                  <img
                    src={filter}
                    alt="filter logo"
                    // className="header-icons"
                    // onClick={() => clearColumnWiseFilter()}
                  />
                )}
              </div>
            </div>

            {pageName !== "Rework" && (
              <>
                <div
                  id="sortZtoA"
                  className="sortAndFrozen"
                  onClick={() => onSort(selectedColumnName, "desc")}
                >
                  {sortData &&
                  sortData.length &&
                  sortData[0] === selectedColumnName &&
                  sortData[1] === "desc" ? (
                    <div className="text-color"> {sortZtoA} </div>
                  ) : (
                    <div> {sortZtoA}</div>
                  )}
                </div>
                <div
                  id="sortAtoZ"
                  className="sortAndFrozen"
                  onClick={() => onSort(selectedColumnName, "asc")}
                >
                  {sortData &&
                  sortData.length &&
                  sortData[0] === selectedColumnName &&
                  sortData[1] === "asc" ? (
                    <div className="text-color"> {SortAtoZ} </div>
                  ) : (
                    <div> {SortAtoZ}</div>
                  )}
                </div>
              </>
            )}
            {/* {pageName!=='planning'&&(
              <div
                id="frozen"
                className="sortAndFrozen"
                onClick={() => {
                  handleToggleFreeze();
                }}
              >
                {frozenCoulmns?.includes(selectedColumnName) ? (
                  <div className="text-color">{Frozen} </div>
                ) : (
                  <div> {Freeze}</div>
                )}
  
              </div>
            )} */}
            <div className="multiSelect">
              <MultiSelect
                value={columnWiseSelectedFields}
                onChange={(e) => onGlobalFilterChange(e, selectedColumnName)}
                options={optionListFinal}
                itemTemplate={mutilSelectTemplate}
                filter
                placeholder={`Select ${selectedColumnName}`}
                maxSelectedLabels={3}
                className="p-column-filter"
                panelClassName="filterPopoverWidth"
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="filter-inner">
      <OverlayPanel ref={op}>{confirmPopData()}</OverlayPanel>
    </div>
  );
};
export default ACPFilter;

import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useState, useEffect } from "react";
import { ExportSelectedRows } from "../../ExportCSV";
import filter from "../../../assets/images/filter.svg";
import DropdownButton from "react-bootstrap/DropdownButton";
import BlueFilterIcon from "../../../assets/images/BlueFilterIcon.svg";
import searchMyProjects from "../../../assets/images/searchMyProjects.svg";
import searchMyProjectsClicked from "../../../assets/images/searchMyProjectsClicked.svg";
import exapandCollapseAll from "../../../assets/images/expandCollapseAll.svg";
import { CheckReadOnlyAccess, changeDateFormat, Loading } from "../../../utils";

const ProjectListHeader = ({
  header,
  allData,
  headers,
  selected,
  filename,
  setVisible,
  actionFlag,
  filterFLag,
  clearFilters,
  expandedKeys,
  // exportCSV,
  onSearchClick,
  isResetEnabled,
  // saveSettings,
  isFilterEnabled,
  isTreeTableFlag,
  projectPlanView,
  // exportCSVTasks,
  CustomizeViewFlag,
  ResetToDefaultFlag,
  handleDelegateClick,
  handleHelpNeededClick,
  handleHelpProvidedClick,
  handleExapandCollapseAllButton,
  onChildClick,
  isTaskPage,
  clearFiltersResetToDefault
}) => {
  let modifiedAllData = allData;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  // const [showCSV, setShowCSV] = useState(true);
  if (isTreeTableFlag) {
    modifiedAllData = allData?.map((obj) => obj.data);
  }
  // const [downloadCSV, setDownloadCSV] = useState(false);
  const shouldShowResetButton =
    location.pathname.includes("/allTasks") || location.pathname.includes("/myTasks");
  const [isClicked, setIsClicked] = useState(false);
  const [isfilterClicked, setIsFilterClicked] = useState(false);

  const handleHelpProvided = () => {
    handleHelpProvidedClick();
  };

  const handleHelpNeeded = () => {
    handleHelpNeededClick();
  };

  const handleDelegate = () => {
    handleDelegateClick();
  };

  const handleLoading = () => {
    setLoading(true);
  };

  const handleClick = () => {
    const newIsClicked = !isClicked;
    setIsClicked(newIsClicked);
    onSearchClick();
    if (onChildClick) {
      onChildClick(newIsClicked);
    }
  };
  const handleClearFilter = () => {
    const newIsClicked = !isFilterEnabled;
    setIsClicked(newIsClicked);
    setIsFilterClicked(newIsClicked);
    if (onChildClick) {
      onChildClick(newIsClicked);
    }
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
      handleExapandCollapseAllButton();
    }
  }, [loading]);

  return (
    <div className="actions" style={{ paddingRight: 42 }}>
      <div className="project-title">{header}</div>

      <div className="action-buttons">
        <img
          alt="filter"
          hidden={filterFLag}
          onClick={isFilterEnabled ? handleClearFilter : undefined}
          className={isFilterEnabled ? "header-icons" : ""}
          src={isFilterEnabled ? BlueFilterIcon : filter}
        />
        <img
          src={isClicked ? searchMyProjects : searchMyProjectsClicked}
          alt="search"
          onClick={handleClick}
          className="header-icons"
        />
        {projectPlanView && (
          <>
            {loading ? (
              <Loading />
            ) : (
              <img
                alt=""
                src={exapandCollapseAll}
                onClick={() => handleLoading()}
                data-pr-tooltip="Expand/Collapse All"
                className={`expand-collapse ${
                  expandedKeys?.length !== 0 ? "invertedIcon" : "nonInvertedIcon"
                }`}
              ></img>
            )}
            {/* <ExportSelectedRows
              allData={allData}
              selectedRows={[]}
              headers={headers}
              filename={filename}
            /> */}
          </>
        )}

        {!shouldShowResetButton && (
          <>
            <ExportSelectedRows
              headers={headers}
              filename={filename}
              selectedRows={selected}
              allData={modifiedAllData}
            />
            <Button
              variant="secondary"
              className="button-layout"
              hidden={CustomizeViewFlag}
              onClick={() => setVisible(true)}
            >
              Customize View
            </Button>
            <button
              type="button"
              onClick={() => {
                clearFiltersResetToDefault(); // this button is only present in my projects and all projects pages //AWM-2805
              }}
              disabled={!isResetEnabled}
              hidden={ResetToDefaultFlag}
              className={
                isResetEnabled ? "btn btn-primary reset-to-default-view" : "btn btn-disabled"
              }
            >
              Reset to Default
            </button>
          </>
        )}
        <>
          {shouldShowResetButton && (
            <>
              <ExportSelectedRows
                headers={headers}
                filename={filename}
                selectedRows={selected}
                allData={modifiedAllData}
              />

              {isTaskPage && (
                <DropdownButton
                  title="Actions"
                  disabled={actionFlag}
                  className="dropdown-button-custom"
                  id={actionFlag ? "disabled" : "tasksActive"}
                >
                  <Dropdown.Item onClick={handleDelegate}>Delegate</Dropdown.Item>
                  <Dropdown.Item onClick={handleHelpNeeded}>Help Needed</Dropdown.Item>
                  <Dropdown.Item onClick={handleHelpProvided}>Help Provided</Dropdown.Item>
                </DropdownButton>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default ProjectListHeader;

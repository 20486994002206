import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLocation, useParams, useNavigate } from "react-router";
import LoadingOverlay from "react-loading-overlay-ts";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import _ from "lodash";
import { redirectToProjectPlanOrTaskPage } from "../../utils";
import "./reworkInput.scss";
import TaskHeader from "../AWMJobs/DesignJobs/TaskHeader";
import PageLayout from "../PageLayout";
import { getTaskDetails } from "../../store/actions/taskDetailAction";
import { updateInputReworkTask } from "../../apis/ReworkApi";
import { messages } from "../../constants/reworkPageTasks";
import { SpecialProjectAction } from "../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../utils";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../common/NavigationPrompt";
import { CustomBreadCrum } from "../common/CustomBreadcrum";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../Utils/helpers";
import UnAuthorizedComponent from "../UnAuthorized/UnAuthorizedComponent";

function ReworkInput() {
  const dispatch = useDispatch();
  const location = useLocation();
  const _currentUrl = location?.pathname;
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [selectDoc, setSelectDoc] = useState([]);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const [loader, setloader] = useState(false);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [isEnable, setisEnable] = useState(false);
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [type, setType] = useState("");
  const { markDirty, markClean } = useUnsavedChangesWarning();

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  let currentUrl = "";
  const items = [];
  const toast = useRef(null);

  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  const {
    userDetails: { AccessGroup ,UserGroup},
  } = useSelector((state) => state?.UserDetailsReducer);
  const [accessType, setAccessType] = useState("Write");
  const [accessSecurityMatrix, setUserAccess] = useState(false);

 
  // 3109 security matrix
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup
    }
  }

  useEffect(()=>{
    setUserAccess(getUserAccessForTask(TaskDetailsData));
    setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
    setAccessType(TaskDetailsData?.AccessType);
  },[TaskDetailsData?.AccessType])

  useEffect(() => {
    if (User) {
      if (
        userInformation?.userid === data?.Assignee &&
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status !== "Complete"
      ) {
        setisEnable(true);
      } else {
        setisEnable(false);
      }
    }
  }, [User, data]);

  const getKeyIftrue = () => {
    const { TechnicalDrawing, CopyPOAXML } = TaskDetailsData?.ArtworkAgilityTasks[0];
    const result = [];
    if (TechnicalDrawing) {
      result.push("TechnicalDrawing");
    }
    if (CopyPOAXML) {
      result.push("copyOfPOA");
    }
    setSelectDoc(result);
  };
  const getUrl = (e) => {
    switch (e) {
      case "allProjects":
        return (currentUrl = `${currentUrl}/${e}`);
      case "myProjects":
        return (currentUrl = `${currentUrl}/${e}`);
      case "projectPlan":
        return (currentUrl = `${currentUrl}/${e}/${ProjectID}`);
      case "rework":
      case "RWK":
      case "allTasks":
      case "myTasks":
        return (currentUrl = `${currentUrl}/${e}`);
      case "task":
        return (currentUrl = `${currentUrl}/${e}/${TaskID}`);
      default:
        currentUrl = `${currentUrl}/${e}`;
    }
  };

  const onDocSelection = (e) => {
    let _selectDoc = [...selectDoc];
    if (e.checked) _selectDoc.push(e.value);
    else _selectDoc.splice(_selectDoc.indexOf(e.value), 1);
    setSelectDoc(_selectDoc);
    setPageEdited(true);
  };

  useEffect(() => {
    if(checkReadWriteAccess)
      {
    dispatch(getTaskDetails(TaskID, ProjectID,userAccessPayLoad));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,checkReadWriteAccess]);

  useEffect(() => {
    location.pathname
      ?.split("/")
      .filter((home) => {
        return home !== "" && home !== ProjectID && home !== TaskID;
      })
      .forEach((ele, i, arr) => {
        const obj = {};
        if (arr.length - 1 === i) {
          items.push({
            ...obj,
            label: _.startCase(ele) === "RWK" ? "Rework" : _.startCase(ele),
            url: getUrl(ele).endsWith('RWK') ? "" : getUrl(ele),
            template: () => {
              return (
                <span className="lastElement">
                  {_.startCase(ele) === "RWK" ? "Rework" : _.startCase(ele)}
                </span>
              );
            },
          });
        } else {
          items.push({ ...obj, label: _.startCase(ele), url: getUrl(ele) });
        }
      });
    setBreadCrumbs(items);
    if (TaskDetailsData?.ArtworkAgilityTasks) {
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      getKeyIftrue();

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(_currentUrl, specialProjectFlag, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TaskDetailsData]);

  const showSuccess = (messages, summary, severity) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: messages,
      life: 2000,
    });
  };

  const handleRedirectionClick = () => {
    if (pageEdited) {
      setType("Redirection");
      setShowDialog(true);
    } else {
      handleRedirection();
    }
  };

  const handleRedirection = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };
  const handleSubmit = async () => {
    setloader(true);
    const headers = {
      key: "If-Match",
      value: TaskDetailsData.ArtworkAgilityPage?.Etag,
    };
    const payLoad = {
      caseTypeID: "PG-AAS-Work-InputReworkTask",
      content: {
        AWMTaskID: TaskID,
        AWMProjectID: ProjectID,
        TechnicalDrawing: selectDoc.includes("TechnicalDrawing"),
        CopyPOAXML: selectDoc.includes("copyOfPOA"),
      },
    };
    const response = await updateInputReworkTask(payLoad, data?.Task_Key, headers);
    setloader(false);
    if (response.status === 200) {
      showSuccess(messages.successMessage, "Success", "success");
      setTimeout(handleRedirection, 2000);
    } else {
      showSuccess(messages.errorMessage, "Error", "danger");
    }
  };

  const handleCrossIcon = () => {
    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${ProjectID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const handleClickCrossIcon = () => {
    if (pageEdited) {
      setShowDialog(true);
    } else {
      handleCrossIcon();
    }
  };

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  };
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    setShowDialog(false);
    if (type === "Redirection") {
      handleRedirection();
    } else {
      handleCrossIcon();
    }
  };

  return (
    <div className="reworkInput-container">
      <PageLayout>
        <LoadingOverlay active={loader} spinner text="">
          <Toast ref={toast} />
          {accessSecurityMatrix?
            <>
          <div className="breadcrum-cross-icon-wrapper">
            {/* <BreadCrumb model={breadCrumbs} separatorIcon={<i className="pi pi-angle-right"></i>} /> */}
            <div className="breadcrums-wrapper">
              <CustomBreadCrum
                items={breadCrumbs}
                iconColorBlack={false}
                className="rework-input"
                lastChildSecondChild={true}
              />
            </div>
            <i
              className="pi pi-times"
              // onClick={() => {
              //   if (page2 && page2 === "projectPlan") {
              //     navigate(`/${page1}/${page2}/${ProjectID}`);
              //   } else if (pageType) {
              //     navigate(`/${pageType}`);
              //   }
              // }}
              onClick={handleClickCrossIcon}
            ></i>
          </div>
          <div className="project-title">{data && data?.Task_Name}</div>
          <div className="task-details">
            {
              <TaskHeader
                {...data}
                pageEdited={pageEdited}
                setPageEdited={setPageEdited}
                TaskDetailsData={TaskDetailsData}
                approve={true}
                approvalStatus={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.ApprovalStatus}
              />
            }
            <Row>
              <Col sm={12}>
                <div className="task_header">
                  <div className="project_name">{data && data?.Task_Name}</div>
                </div>
              </Col>
              <Col>
                <div className="checkbox_wrapper flex flex-wrap gap-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      value="TechnicalDrawing"
                      checked={selectDoc.includes("TechnicalDrawing")}
                      onChange={onDocSelection}
                      disabled={!isEnable || !accessType}
                    />
                    <label htmlFor="technicalDrawing" className="ml-2">
                      Technical Drawing
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox
                      value="copyOfPOA"
                      checked={selectDoc.includes("copyOfPOA")}
                      onChange={onDocSelection}
                      disabled={!isEnable || !accessType}
                    />
                    <label htmlFor="copyOfPOA" className="ml-2">
                      Copy (POA .xml).
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <footer>
            <Button variant="secondary" onClick={handleRedirectionClick}>
              Back
            </Button>
            <Button
              disabled={!isEnable || !accessType}
              className={selectDoc.length === 0 ? "disabled" : ""}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </footer> 
          </> : <UnAuthorizedComponent nowrapper={true} />}
        </LoadingOverlay>

        {/* UnsavedChangesDialog popup*/}
        <UnsavedChangesDialog
          visible={showDialog}
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
      </PageLayout>
    </div>
  );
}

export default ReworkInput;

import React, { useEffect, useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import moment from "moment";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import FooterButtons from "../DesignJobs/FooterButtons";
import AddNewDesign from "../DesignJobs/TaskHeader";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadtoAzureStrContainer } from "../../../apis/uploadAzuerContainer";
import { Toast } from "primereact/toast";
import {
  viewThumbnail,
  redirectToProjectPlanOrTaskPage,
  sanitizeFileName,
  getEnvironmentFromURL,
} from "../../../utils";
import UploadDesignIntentProofscope from "../DesignJobs/UploadDesignIntentProofscope";
import { uploadtoAzurefileShare } from "../../../apis/uploadAzureFileShareApi";
import { Message } from "primereact/message";
import {
  postSaveAsDraftUploadCIC,
  submitUploadCIC,
} from "../../../apis/uploadCICApi";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { useProofScopeURL } from "../../ProofScope/ViewFiles";
import { isEmpty, isString } from "lodash";
import { totalFileSizeQuotient } from "../../../constants/FileUploadConstant";
import { AzureContainerFail, AzureContainerSuccess, AzureFileShareFail, AzureFileShareSuccess, ContainerAndFileShareFailMsg, ContainerFailMsg, FileShareFailMsg } from "../../../Utils/constants";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";

const breadcrumb = [
  { label: "My Tasks", url: "/tasks" },
  { label: "Upload CIC" },
];

const UCIC = () => {
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [date, setDate] = useState("");
  const [version, setVersion] = useState("V0");
  const [designIntent, setDesignIntent] = useState(null);
  const [formattedValue, setformattedValue] = useState(0);
  const [fileName, setFileName] = useState("");
  const [fileDetails, setFileDetails] = useState();
  const [viewFileName, setViewFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  const [thumbnailErrorMsg, setThumbnailErrorMsg] = useState("");
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const { TaskDetailsData, loading } = useSelector(
    (state) => state.TaskDetailsReducer
  );
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const roleName = "UCIC_";
  const location = useLocation();
  const currentUrl = location.pathname;
  // const checkReadWriteAccess = CheckReadOnlyAccess();
  // const checkReadWriteAccess = true;
  const {
    userDetails: { UserGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup
    }
  }
  const [accessType, setAccessType] = useState("Write");
  const [accessSecurityMatrix, setUserAccess] = useState(false);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);

  const env = getEnvironmentFromURL();
  const sanitizedBuName = sanitizeFileName(
    TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU
  );
  const sanitizedTaskFolder = sanitizeFileName(`CIC`);
  const filesexist = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
    ?.FileMetaDataList?.[0]?.File_Name;

  const folderStructure = useAzureUploadFolderStructure('CIC', version);
  const { viewProofScopeFile } = useProofScopeURL();
  const [readonlyUrl, setReadonlyUrl] = useState("");
  const [formData, setFormData] = useState(null);
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [FileUploaded, setFileUploaded] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [FormObjects, setFormObjects] = useState({fileList:[]});
  const [InitialFormObjects, setInitialFormObjects] = useState({fileList:[]});

  useEffect(()=>{
    const isObjectEqual = JSON.stringify(InitialFormObjects) === JSON.stringify(FormObjects)
    if(!isObjectEqual && FileUploaded){
      setPageEdited(true);
    }else{
      setPageEdited(false);
      setFileUploaded(false);
    }
  },[FormObjects])

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  useEffect(()=>{
    setUserAccess(getUserAccessForTask(TaskDetailsData));
    setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
    setAccessType(TaskDetailsData?.AccessType);
  },[TaskDetailsData?.AccessType])

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    setAzureFileUploadMsg(false);
    setThumbnailErrorMsg("");
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID === TaskID) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
          ?.FileMetaDataList?.[0] || [];
      if (data) {
        data.Version !== "" && setVersion(data.Version);
        data.Timestamp !== "" &&
          setDate(
            moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format(
              "DD-MMMM-YYYY"
            )
          );
      }
    }
  }, [TaskDetailsData]);

  useEffect(() => {
    if (!isEmpty(readonlyUrl) && !isEmpty(formData)) {
      (async () => {
        const payload = { ...formData }
        if (!isEmpty(payload)) {
          payload.content['ViewPFURL'] = readonlyUrl
        }
        const headers = {
          key: "If-Match",
          value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
        };
        await submitUploadCIC(formData, id, headers);
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "File Uploaded",
            detail: "Successfully!",
            life: 3000,
          });
        }
        await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
        redirectToProjectPlanOrTaskPage(
          page1,
          page2,
          pageType,
          ProjectID,
          navigate
        );
        setLoader(false);
        setAzureFileUploadMsg(false);
        setThumbnailErrorMsg("");
      })()
    }
  }, [readonlyUrl, formData])

  const handleCancelClick = () =>{
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setShowDialog(false);
  }

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(
      page1,
      page2,
      pageType,
      ProjectID,
      navigate
    );
  };

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;

  // Define a function to generate the formData object
  const generateSaveAsDraftFormData = (filePath, fileValue) => {
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    return {
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      Size: fileSize === 0 ? "1" : fileSize,
      Version: version,
      Filename: azureFile?.File_Name ? azureFile?.File_Name : fileValue,
      Filepath:
        filePath === ""
          ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
            ?.FileMetaDataList?.[0]?.File_Path
          : filePath,
      Full_Name: fileDetails?.Full_Name,
      Email: fileDetails?.Email
    };
  };
  const generateSubmitFormData = (filePath, fileValue) => {
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    return {
      caseTypeID: "PG-AAS-Work-UploadCIC",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
        Filename: azureFile?.File_Name ? azureFile?.File_Name : fileValue,
        Version: version,
        Size: fileSize === 0 ? "1" : fileSize,
        Filepath:
          filePath === ""
            ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
              ?.FileMetaDataList?.[0]?.File_Path
            : filePath,
        Full_Name: fileDetails?.Full_Name,
        Email: fileDetails?.Email,
        JobID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
        JobName: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_Name,
        TypeOfFile: azureFile?.File_Name ? azureFile?.File_Name?.split('.').pop() : fileValue?.split('.').pop()
      },
    };
  };
  const onSaveAsDraft = async () => {
    try {
      if (!(filesexist === azureFile) && azureFile !== '') {
        await uploadtoAzureStrContainer(
          azureFile,
          folderStructure
        );
        let res = await uploadtoAzurefileShare(
          azureFile?.file ? azureFile?.file : filesexist,
          BU,
          `CIC`,
          ProjectID,
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
          version
        );
        // alert(res);
        if (res) {
          const fileurl =
            fileName !== "" && fileName?.endsWith(".zip")
              ? sanitizeFileName(res)
              : fileName !== "" && !fileName?.endsWith(".zip")
                ? sanitizeFileName(fileName)
                : sanitizeFileName(filesexist);
          const thumnailResponse = await viewThumbnail(
            env,
            sanitizedBuName,
            sanitizedTaskFolder,
            fileurl,
            TaskID,
            ProjectID,
            TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
            version
          );
          if (thumnailResponse.thumbnail) {
            const formData = generateSaveAsDraftFormData(
              thumnailResponse.thumbnail,
              fileurl
            );
            if (fileName?.endsWith(".zip")) {
              setFileName(res);
            }
            await postSaveAsDraftUploadCIC(formData);
            if (toast.current) {
              toast.current.show({
                severity: "success",
                summary: "File Uploaded",
                detail: "Successfully!",
                life: 3000,
              });
            }
            // }
            await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
            setLoader(false);
            setAzureFileUploadMsg(false);
          } else {
            setLoader(false);
            setAzureFileUploadMsg(true);
          }
        } else {
          setLoader(false);
          setAzureFileUploadMsg(true);
        }
      } else {
        if (
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status ===
          "In-Progress" &&
          filesexist !== ""
        ) {
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "File Uploaded",
              detail: "Successfully!",
              life: 3000,
            });
          }

          const formData = generateSaveAsDraftFormData("", filesexist);
          await postSaveAsDraftUploadCIC(formData);
          await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
          setLoader(false);
          setAzureFileUploadMsg(false);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    setLoader(true);
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    try {
      if (!(filesexist === azureFile) && azureFile !== '') {
        Promise.all(
          [uploadtoAzureStrContainer(azureFile, folderStructure),
          uploadtoAzurefileShare(
            azureFile,
            BU,
            `CIC`,
            ProjectID,
            TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
            version
          )]
        ).then(function (responses){
          const azureRes = responses[0]
          const res = responses[1]
          if(azureRes){
            if (toast.current) {
              toast.current.show(AzureContainerSuccess);
            }
          } else {
            if (toast.current) {
              toast.current.show(AzureContainerFail);
            }
          }
          if(res){
            if (toast.current) {
              toast.current.show(AzureFileShareSuccess);
            }
          } else {
            if (toast.current) {
              toast.current.show(AzureFileShareFail);
            }
          }
          return responses
        }).then(async(data)=>{
          const azureRes = data[0]
          const res = data[1]
          if (azureRes && res) {
            const fileurl =
              fileName !== "" && fileName?.endsWith(".zip")
                ? sanitizeFileName(res)
                : fileName !== "" && !fileName?.endsWith(".zip")
                  ? sanitizeFileName(fileName)
                  : sanitizeFileName(filesexist);
            const thumnailResponse = await viewThumbnail(
              env,
              sanitizedBuName,
              sanitizedTaskFolder,
              fileurl,
              TaskID,
              ProjectID,
              TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
              version
            );

            if (thumnailResponse.thumbnail) {
              const designJobId = sanitizeFileName(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID)
              const fileVersion = isString(version) ? version.substring(0, 1) + (parseInt(version.substring(1)) + 1) : version
              const assignee = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Assignee
              const filePath = `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${ProjectID}/${designJobId}/${fileVersion}/${fileurl}`;

              // await viewProofScopeFile(
              //   ProjectID,
              //   id,
              //   TaskID,
              //   filePath,
              //   assignee,
              //   "UploadCICTemplate",
              //   "UploadCICTemplate_Flow",
              //   1
              // );
              setReadonlyUrl(thumnailResponse.readonly_url);
              const formData = generateSubmitFormData(thumnailResponse.thumbnail, fileurl);
              setFormData(formData)
            } else {
              setLoader(false);
              setAzureFileUploadMsg(true);
              setThumbnailErrorMsg(thumnailResponse?.contents?.message);
            }
          } else {
            const msg = (!azureRes && !res) 
            ? ContainerAndFileShareFailMsg 
            : !azureRes 
            ? ContainerFailMsg
            : !res 
            ? FileShareFailMsg
            : "File upload failed!  Please try after some time."
            setLoader(false);
            setAzureFileUploadMsg(true);
            setThumbnailErrorMsg(msg);
          }
        })
        
      } else {
        if (
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status ===
          "In-Progress" &&
          filesexist !== ""
        ) {
          const formData = generateSubmitFormData("", filesexist);
          await submitUploadCIC(formData, id, headers);
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "File Uploaded",
              detail: "Successfully!",
              life: 3000,
            });
          }
          await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
          redirectToProjectPlanOrTaskPage(
            page1,
            page2,
            pageType,
            ProjectID,
            navigate
          );
          setLoader(false);
          setAzureFileUploadMsg(false);
          setThumbnailErrorMsg("");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };

  return (
    <PageLayout>
      <Toast ref={toast} />
      { loader || loading ? (
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="" />
      ) : accessSecurityMatrix ?
      designIntent !== null &&(
        <>
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        pageEdited={pageEdited}
        lastLabel={"UCIC"}
        setPageEdited={setPageEdited}
        label={shortName}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        {...data}
      />
      <div className="task-details">
        {
          <AddNewDesign
            {...data}
            checkReadWriteAccess={checkReadWriteAccess}
            TaskDetailsData={TaskDetailsData}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
          />
        }
          {designIntent && (
            <UploadDesignIntentProofscope
              {...designIntent}
              designIntent={designIntent}
              upload={true}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              azureFile={azureFile}
              setFileName={setFileName}
              item={data}
              roleName={roleName}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              version={version}
              setVersion={setVersion}
              date={date}
              setFormObjects={setFormObjects}
              setFileUploaded={setFileUploaded}
              checkReadWriteAccess={checkReadWriteAccess}
              fileName={fileName}
              buName={BU}
              pageEdited={pageEdited}
              setPageEdited={setPageEdited}
              taskFolder={`CIC`}
              setAzureFileUploadMsg={setAzureFileUploadMsg}
              TaskID={TaskID}
              projectName={projectName}
              ProjectID={ProjectID}
              viewFileName={viewFileName}
              setViewFileName={setViewFileName}
              setFileDetails={setFileDetails}
              toShowReferenceDocuments={true}
              loading={loading}
              azureFileUploadMsg={azureFileUploadMsg}
              thumbnailErrorMsg={thumbnailErrorMsg}
            />
          )
          }
      </div>

      <FooterButtons
        accessType={accessType}
        handleCancel={handleCancelClick}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        hideSaveButton={true}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        formValid={
          (fileName === "") ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" || TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled"
        }
        saveAsDraftEnable={
          (fileName === "" && viewFileName === "") ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" || TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled"
        }
        {...data}
      />
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
      </>)
      : <UnAuthorizedComponent nowrapper={true} />
      }
    </PageLayout>
  );
};
export default UCIC;

import jwt_decode from 'jwt-decode';
import { isEmpty, isObject } from 'lodash';
import { publicRoutes } from '../router/routes';
import { store } from '../store/store';
import { updateToken } from '../store/actions/AuthenticationActions';
import { dropDownValuesAction } from '../store/actions/dropDownValuesAction';
import { getDropDownValues } from '../apis/getDropDownValues';
import { getUserDetails } from '../apis/getUserDetails';
import { navigateBasedOnRole } from '../utils';
import { createSearchParams } from 'react-router-dom';

export const redirectToAuth2Url = () => {
    window.location.href = `${process.env.REACT_APP_AUTHORIZED_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&grant_type=${process.env.REACT_APP_GRANT_TYPE}&scope=pingid+openid`;
}

export const getLocalStorageData = () => {
    return Object.assign({}, localStorage)
}

export const getAccessToken = async (code) => {
    const data = new URLSearchParams();
    data.append('code', code);

    const response = await fetch(process.env.REACT_APP_AUTH2_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
    });

    console.info('Response from oauth2 on exchanging token', response)

    if (!response.ok) {
        console.error('Failed to exchange code for token');
        return 'Failed to fetch access token';
    } else {
        return await response.json();
    }
}

export const setAuthData = (payload) => {
    const token = payload.access_token;

    // NOTE: As of now setting required data in localStorage because tremendous usage of it in other area. Needs to move it to redux later.
    if (isOAuth2Disabled() && !isEmpty(payload) && isObject(payload)) {
        localStorage.setItem('userName', payload.username)
        localStorage.setItem('FirstName', payload.username); //don't have a firstName in userRole.js file
        localStorage.setItem('LastName', payload.lastName);
        localStorage.setItem('region', payload.region || 'NA')
        localStorage.setItem('userId', payload.userid)
        localStorage.setItem('role', payload.role)
        localStorage.setItem('bu', payload.role)
        localStorage.setItem('initials', `${payload.username.charAt()} ${payload.lastName.charAt()}`)
    } else if (!isEmpty(token)) {
        const decodedToken = jwt_decode(token);
        const userid = decodedToken['Username']?.split("@")[0];
        localStorage.setItem('tokenNumber', payload?.access_token);
        localStorage.setItem('refreshToken', payload?.refresh_token);
        localStorage.setItem(
            'tokenExpiryON',
            new Date().setSeconds(new Date().getSeconds() + payload?.expires_in)
        );

        localStorage.setItem('initials', `${decodedToken.FirstName.charAt()} ${decodedToken.LastName.charAt()}`)
        localStorage.setItem('userName', decodedToken['Username']);
        localStorage.setItem('FirstName', decodedToken.FirstName);
        localStorage.setItem('LastName', decodedToken.LastName);
        localStorage.setItem('userId', userid);
        localStorage.setItem('region', decodedToken.Region);
    } else {
        throw new Error('Invalid token while decoding')
    }
}

export const hasRequiredAuthData = () => {
    const expectedData = ['initials', 'userName', 'region', 'userId']

    const data = Object.assign({}, localStorage)
    const isValid = expectedData.every(key => Object.keys(data).includes(key) && !isEmpty(data[key]))
    return isValid
}

export const getPingIdCode = () => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get('code');
}

export const validateCodeOnRedirect = () => {
    let validated = false, errors = []
    try {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let code = url.searchParams.get('code');
        if (
            code !== null &&
            code.length > 2
        ) {
            validated = true
        }
    } catch (error) {
        console.error(error);
        errors.push(error)
        validated = false
    }

    return { validated, errors }
}

// Detecting public routes, which helps to exclude from auth process
export const hasPublicRoutes = () => publicRoutes.find((route) => route.path === window.location.pathname)

// Help to set data on local and redirecting to the appropiate page as per role or current route
export const processRedirectionAfterLogin = async (data, navigate) => {
    const currentRoute = localStorage.getItem('currentRoute')

    setAuthData(data)
    store.dispatch(updateToken(data))

    const userid = localStorage.getItem('userId')
    const allDropDown = await getDropDownValues();
    store.dispatch(dropDownValuesAction(allDropDown));

    const userDetails = await getUserDetails(userid)
    if (!isEmpty(userDetails)) {
        let navigateUrl = navigateBasedOnRole(userDetails.AccessGroup);
        localStorage.setItem('navigateUrlBasedOnRole', navigateUrl)
        if (!isEmpty(navigateUrl)) {
            if (!isEmpty(currentRoute) && currentRoute !== navigateUrl && currentRoute !== '/') {
                navigateUrl = currentRoute
            }

            navigate({
                pathname: navigateUrl,
                search: createSearchParams({
                    access: 'granted',
                    redirected: true,
                }).toString(),
                replace: true,
            })
        } else {
            navigate({
                pathname: '/unauthorised',
                search: createSearchParams({
                    access: 'denied',
                    redirected: true,
                }).toString(),
                replace: true,
            })
        }
    } else {
        throw new Error()
    }
}

// Returning boolean value on the basis of env variable
export const isOAuth2Disabled = () => process.env.REACT_APP_DISABLE_OAUTH2 === 'Y' || process.env.REACT_APP_DISABLE_OAUTH2 === 'y'

export const SuggestionsMatch=(list,value)=>{
    return list?.includes(value);
}

export const showBackgroundProcess = (toast) => {
    toast?.current?.show({
      severity: "warn",
      summary: "Background process initiated...",
      detail: "Please wait for few minutes and refresh the screen for updated information.",
      life: 3500,
    });
  };

export const getUserAccessForTask = (TaskDetailsData) => {
    let userAccess = true;
    if(TaskDetailsData?.AccessType==="Hidden"){
        userAccess=false;
    }else if(TaskDetailsData?.AccessType === "Read" || TaskDetailsData?.AccessType === "Write"){
        userAccess=true;
    }
    return userAccess;
}

export const getReadWriteAccessForTask = (TaskDetailsData) => {
    let readWriteAccess = true;
    if(TaskDetailsData?.AccessType==="Hidden" || TaskDetailsData?.AccessType === "Read"){
        readWriteAccess=false;
    }else if(TaskDetailsData?.AccessType === "Write"){
        readWriteAccess=true;
    }
    return readWriteAccess;
}
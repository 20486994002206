import React, { useState } from "react";
import searchMyProjectsClicked from "../../assets/images/searchMyProjectsClicked.svg";
import searchMyProjects from "../../assets/images/searchMyProjects.svg";
import filter from "../../assets/images/filter.svg";
import BlueFilterIcon from "../../assets/images/BlueFilterIcon.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ExportSelectedRows } from "../ExportCSV";
import { CheckReadOnlyAccess, changeDateFormat } from "../../utils";
import _ from "lodash";
import { cloneDeep } from "lodash";
import { artworkStatusExportCSVFlags } from "./utils";
import { exportExcelColumnNames } from "./constant";
import CustomizeView from "../common/CustomizeView";

const ArtworkStatusListHeader = ({
  header,
  clearFilter,
  clearFiltersResetToDefault,
  setVisible,
  // saveSettings,
  onSearchClick,
  // exportCSV,
  isFilterEnabled,
  isResetEnabled,
  handleDelegateClick,
  handleHelpNeededClick,
  handleHelpProvidedClick,
  actionFlag,
  // exportCSVTasks,
  selected,
  allData,
  headers,
  filterFLag,
  CustomizeViewFlag,
  ResetToDefaultFlag,
  isTreeTableFlag,
  AWPrinterDate,
  customizeViewFields,
  setCustomizeViewFields,
  projectColumnName,
  headerName,
  onChildClick,
  setTableRender,
  tableRender,
  isSpecialProject,
  bu,
  region,
  reorderColumnsSetColumnOrder,
}) => {
  const [customizeViewVisible, setCustomizeViewVisible] = useState(false);
  let modifiedAllData = cloneDeep(allData);
  if (isTreeTableFlag) {
    modifiedAllData = allData?.map((obj) => {
      return obj.data;
    });
  }
  const [isClicked, setIsClicked] = useState(false);
  const [isfilterClicked, setIsFilterClicked] = useState(false);

  const alterColumnsforExport = () => {
    let jsonValue =
      customizeViewFields && customizeViewFields?.length > 0
        ? JSON.parse(customizeViewFields)
        : null;
    const allSelectedData = jsonValue?.freezedColumns?.fieldsData
      ? (jsonValue?.freezedColumns?.fieldsData).concat(jsonValue?.selectedFields?.fieldsData)
      : jsonValue?.selectedFields?.fieldsData;
    let selectedData = allSelectedData || exportExcelColumnNames;
    return selectedData;
  };

  const handleClick = () => {
    const newIsClicked = !isClicked;
    setIsClicked(newIsClicked);
    onSearchClick();
    if (onChildClick) {
      onChildClick(newIsClicked);
    }
  };

  const handleClearFilter = () => {
    const newIsClicked = !isFilterEnabled;
    setIsClicked(newIsClicked);
    setIsFilterClicked(newIsClicked);
    clearFilter();
    if (onChildClick) {
      onChildClick(newIsClicked);
    }
  };
  let modifiedHeaders = alterColumnsforExport();

  modifiedAllData = modifiedAllData?.map((obj, index) => {
    obj["Design"] = artworkStatusExportCSVFlags(obj.DesignFlag, isSpecialProject);
    // obj['SI.No'] = index + 1;
    obj["POA Number"] = obj.POANumber;
    obj["POA Description"] = obj.POADescription;
    obj["Country Of Sales"] = obj.CountryOfSales;
    obj["FA Assembly"] = obj.FAAssemblyState;
    obj["FA Approval (A/A/R)"] = obj.FAApproval.replace(/\//g, "|");
    obj["B:W"] = obj.BufferToWorkRatio;
    obj["B|W"] = obj.BufferToWork;
    obj["Req"] = obj.AWPrinterDate;
    obj["ETA"] = obj.POAReleaseDate;
    obj["Copy"] = obj.ApprovedCopyElements.toString() + "|" + obj.TotalCopyElements.toString();
    obj["Group"] = obj.AWM_GroupPMP;
    obj["PMP State"] = obj.PMPState;
    obj["TD State"] = obj.TDState;
    obj["POA Status"] = obj.RTA_RTAPOAStatus;
    obj["CIC"] = artworkStatusExportCSVFlags(obj.CICFlag, isSpecialProject);
    obj["FA"] = artworkStatusExportCSVFlags(obj.FAFlag.replace(/\//g, "|"), isSpecialProject);
    obj["RTA ProjectID"] = obj.RTA_ProjectID;
    obj["RTA Project Name"] = obj.RTA_Project_Name;
    obj["DSBP InitiativeID"] = obj.DSBP_InitiativeID;
    obj["DSBP Initiative Name"] = obj.DSBP_Initiative_Name;
    obj["PIMaterialNumber"] = obj.PIMaterialNumber;
    obj["PIMaterialID"] = obj.PIMaterialID;
    obj["PIMaterialDescription"] = obj.PIMaterialDescription;
    return obj;
  });

  const location = useLocation();
  let { ProjectID } = useParams();

  // const [downloadCSV, setDownloadCSV] = useState(false);
  // const [showCSV, setShowCSV] = useState(true);

  const shouldShowResetButton =
    location.pathname.includes("/AllTasks") || location.pathname.includes("/MyTasks");
  const handleDelegate = () => {
    handleDelegateClick();
  };
  const handleHelpNeeded = () => {
    handleHelpNeededClick();
  };
  const handleHelpProvided = () => {
    handleHelpProvidedClick();
  };

  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const mytasks = url[1];

  return (
    <div className="actions" style={{ paddingRight: 5, paddingTop: 15 }}>
      {customizeViewVisible && (
        <CustomizeView
          onClose={() => setCustomizeViewVisible(!customizeViewVisible)}
          showTaskDialog={customizeViewVisible}
          setCustomizeViewFields={setCustomizeViewFields}
          customizeViewFields={customizeViewFields}
          allColumns={projectColumnName}
          headerName={headerName}
          resetToPgDefault={() => clearFiltersResetToDefault()}
          setTableRender={setTableRender}
          tableRender={tableRender}
          BU={bu}
          region={region}
          reorderColumnsSetColumnOrder={reorderColumnsSetColumnOrder}
        />
      )}
      <div>
        {/* <BreadCrumb model={breadcrumb} /> */}
        <nav className="p-breadcrumb p-component" aria-label="Breadcrumb">
          <ul>

            {url[2] === "projectPlan" ? (
              <>
                {" "}
                <li className="">
                  <NavLink to={`/${mytasks}`} className="p-menuitem-link">
                    <span className="p-menuitem-text">{_.startCase(mytasks)}</span>
                  </NavLink>{" "}
                </li>{" "}
                <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                <li className="">
                  <NavLink to={`/${mytasks}/${url[2]}/${url[5]}`} className="p-menuitem-link">
                    <span className="p-menuitem-text">{_.startCase(url[2])}</span>
                  </NavLink>{" "}
                </li>
              </>
            ) : (
              <li className="">
                <NavLink to={`/${mytasks}`} className="p-menuitem-link">
                  <span className="p-menuitem-text">{_.startCase(mytasks)}</span>
                </NavLink>{" "}
              </li>
            )}
            <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
            <li className="">
              <a href="#" className="p-menuitem-link">
                <span className="p-menuitem-text">{_.startCase(url[2])}</span>
              </a>
            </li>
            <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"/>
            <li>
              <NavLink to={`/${mytasks}/projectPlan/${ProjectID}`}>
                <div className="nav-link-header">{header}</div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      {/* <div className="project-title">{header}</div> */}

      <div className="action-buttons">
        <img
          alt="filter"
          hidden={filterFLag}
          onClick={isFilterEnabled ? handleClearFilter : undefined}
          className={isFilterEnabled ? "header-icons" : ""}
          src={isFilterEnabled ? BlueFilterIcon : filter}
        />
        <img
          src={isClicked ? searchMyProjects : searchMyProjectsClicked}
          alt="search field"
          onClick={handleClick}
          className="header-icons"
        />
        {!shouldShowResetButton && (
          <>
            <ExportSelectedRows
              allData={modifiedAllData}
              selectedRows={selected}
              headers={modifiedHeaders}
            />
            <Button
              className="button-layout"
              variant="secondary"
              onClick={() => setCustomizeViewVisible(true)}
              hidden={CustomizeViewFlag}
              disabled={allData?.length == 0} //disable customize view if there is no data in the table
            >
              Customize View
            </Button>

            <button
              type="button"
              disabled={!isResetEnabled}
              className={
                isResetEnabled ? "btn btn-primary reset-to-default-view" : "btn btn-disabled"
              }
              onClick={() => {
                clearFiltersResetToDefault();
              }}
              hidden={ResetToDefaultFlag}
            >
              Reset to Default
            </button>
          </>
        )}
        <>
          {shouldShowResetButton && (
            <>
              <ExportSelectedRows
                selectedRows={selected}
                allData={modifiedAllData}
                headers={modifiedHeaders}
              />

              <DropdownButton
                title="Action"
                disabled={actionFlag}
                id={actionFlag ? "tasksInActive" : "tasksActive"}
                className="dropdown-button-custom"
              >
                <Dropdown.Item onClick={handleDelegate}>Delegate</Dropdown.Item>
                <Dropdown.Item onClick={handleHelpNeeded}>Help Needed</Dropdown.Item>
                <Dropdown.Item onClick={handleHelpProvided}>Help Provided</Dropdown.Item>
              </DropdownButton>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default ArtworkStatusListHeader;

import "./index.scss";
import React, { useState, useRef } from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import PDFViewer from "../PDFViewer/PDFviewer";
import { DataTable } from "primereact/datatable";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import downloadPdfIcon from "../../../assets/images/downloadPdfIcon.svg";
import { downloadAzureContainer } from "../../../apis/downloadAzureContainer";
import { Toast } from "primereact/toast";
import { toastMessages, showToast } from "../../../utils";
const Versionhistory = ({
  onHide,
  RowData,
  fileList,
  headerName,
  JobListData,
  ViewModalPDF,
  ProjectDetalis,
  showVersionList,
}) => {
  const versionHistoryColumns = [
    { header: "Version", field: "Version" },
    { header: "Filename", field: "Filename" },
    { header: "Owner", field: "Owner" },
    { header: "Date", field: "Date" },
  ];
  const [path, setPath] = useState("");
  const [ViewPdf, setViewPdf] = useState(false);
  const uniqueFileList = fileList.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.Version === item.Version && t.Filename === item.Filename)
  );
  const toast = useRef(null);
  //3234 -A toast message has been added to inform the user about the download status, as the download API takes more time to complete.
  const downloadPdf = async (event, el) => {
    event.preventDefault();
    const Version = el?.Version;
    const fileName = el?.Filename;
    const filteredChildren = JobListData.filter((el) => el.key === RowData.parentKey);

    // Fetching required data
    const JobId = filteredChildren?.[0]?.children.find((el) => el.key === RowData.keyCode)?.data
      ?.JobID;
    const GroupName = filteredChildren?.[0]?.children.find((el) => el.key === RowData.keyCode)?.data
      ?.Filename;
    const Subfolder = filteredChildren?.[0]?.data?.Filename;
    const filePath = el?.Filepath;
    const targetFolders = ["TechSpecXML", "CICMappingXML", "TD", "TasKXML", "XFDF"];
    const isXfdfFile = fileName?.toLowerCase().endsWith(".xfdf");
    const matchedFolder =
      targetFolders.find((folder) => new RegExp(`\\b${folder}\\b`, "i").test(filePath)) || "";
    const finalFolder = isXfdfFile ? "XFDF" : matchedFolder;

    const folderStructure = finalFolder
      ? `${ProjectDetalis?.BU}/${
          ProjectDetalis?.Region
        }/${ProjectDetalis?.ProjectID?.toUpperCase()}/${Subfolder}/${
          Subfolder === "CPPFA" ? Subfolder : Subfolder === "GA Brief" ? GroupName : JobId
        }/${finalFolder}/${Version}/`
      : `${ProjectDetalis?.BU}/${
          ProjectDetalis?.Region
        }/${ProjectDetalis?.ProjectID?.toUpperCase()}/${Subfolder}/${
          Subfolder === "CPPFA" ? Subfolder : Subfolder === "GA Brief" ? GroupName : JobId
        }/${Version}/`;

    // Show the "Please wait" toast message using the toastMessages object
    showToast(toast, "warn", toastMessages.downloading.detail, { sticky: true });

    try {
      // api call
      await downloadAzureContainer(fileName, folderStructure);
      // Show success toast once download is complete
      showToast(toast, "success", toastMessages.downloadComplete.detail, {
        life: 3000,
        clearPrevious: true,
      });
    } catch (error) {
      // Show error toast if the download fails
      showToast(toast, "error", toastMessages.downloadFailed.detail, {
        life: 3000,
        clearPrevious: true,
      });
    }
  };

  const viewPdf = (options, ViewPFURL) => {
    setViewPdf(true);
    setPath(ViewPFURL);
  };
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        headerClassName="version-history-dialog-header"
        className="version-history-dialog"
        visible={showVersionList}
        style={{ width: "75vw" }}
        header={headerName}
        onHide={onHide}
        maximizable
      >
        <div>
          <DataTable value={uniqueFileList} scrollable scrollHeight="400px">
            {versionHistoryColumns?.map((el) => (
              <Column header={el?.header} field={el?.field} />
            ))}
            <Column
              header="Action"
              body={(el) => {
                return (
                  <div className="flex-display">
                    <img
                      src={downloadIcon}
                      alt="downloadicon"
                      className="download_icon"
                      onClick={(e) => downloadPdf(e, el)}
                    />
                    {ViewModalPDF && (
                      <img
                        alt="downloadpdficon"
                        src={downloadPdfIcon}
                        className="view_pdf_icon"
                        onClick={() => viewPdf(el, el?.ViewPFURL)}
                      />
                    )}
                  </div>
                );
              }}
            ></Column>
          </DataTable>
        </div>
      </Dialog>

      <Dialog
        headerClassName="version-history-dialog-header"
        className="version-history-dialog"
        onHide={() => setViewPdf(false)}
        style={{ width: "80vw" }}
        header={headerName}
        visible={ViewPdf}
        maximizable
      >
        <div>
          <PDFViewer path={path} width={"100%"} height={"600px"} />
        </div>
      </Dialog>
    </div>
  );
};
export default Versionhistory;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { TreeTable } from "primereact/treetable";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import _, { startCase, isEmpty, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay-ts";
import { Toast } from "primereact/toast";
import { Button } from "react-bootstrap";

import {
  updateProjectPlanAction,
  updateProjectPlanDesignAction,
  updateProjectStateAction,
} from "../../../store/actions/ProjectPlanActions";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { setOwnerList } from "../../../store/actions/OwnerListActions";
import { ProjectService } from "../../../service/PegaService";
import {
  getProjectPlan,
  getRolesOwners,
  saveProjectPlanAction,
  activateProjectPlan,
} from "../../../apis/projectPlanApi";
import filter from "../../../assets/images/filter.svg";
import {
  removeEleKey,
  getRestructuredData,
  saveProjectPlanData,
  updateProjectPlanData,
  updateProjectPlanRoleOwner,
  checkIfUpdatedHasRoleOwner,
} from "./util";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { saveProofscopeURL } from "../../../apis/saveProofscopeURL";
import { changeDateFormat, multiFilterChange, isRoleMatch } from "../../../utils";
import { extractData, handleExpandAll, sortBasedOnSelectedValue } from "./util";
import { getDaysLeft } from "../../../Utils/LeftDays";
import { AcpService } from "../../../service/ACPService";
import { AcpCommonService } from "../../../service/AcpCommonService";
import { SortAndFilterService } from "../../../service/SortAndFilterService";
import { setColumnsOrder, getColumnsOrder } from "../../../apis/commonApi";
import ActionButton from "./ActionButton";
import ManageDependency from "../../ManageDependency";
import ProjectListHeader from "../MyProjects/ProjectListHeader";
import CustomizeView from "../../common/CustomizeView";
import CPPFA from "../../AWMJobs/CPPFA";
import ACPFilter from "../../ACP/common/ACPFilter";
import ElementTemplate from "./ElementTemplate";

import "./index.scss";
import { showBackgroundProcess } from "../../../Utils/helpers";
import { CustomBreadCrum } from "../../common/CustomBreadcrum";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";

import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
const headerName = "Project Plan";

const ProjectPlan = () => {
  let { ProjectID } = useParams();
  const op = useRef(null);
  const toast = useRef(null);
  const dropdownRef = useRef(null);
  const childFunc = useRef(null);
  const filterArray = useRef([]);
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const locationPath1 = location?.pathname;
  const url = locationPath1?.split("/");
  const currentUrl = location?.pathname;
  const [dropDownMatch, setDropDownMatch] = useState(true);
  const [loader, setLoader] = useState(true);
  const [projectData, setProjectData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isSearch, isSearchSet] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [width, setWidth] = useState(0);
  const [resetKey, setResetKey] = useState(0);
  const [activeFlag, setActiveFlag] = useState(true);
  const [activeSave, setActiveSave] = useState(true);
  const [projectState, setProjectState] = useState("");
  const [RolesOwners, setRolesOwners] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [DMAssignee, setDMAssignee] = useState("");
  const [finalPermission, setFinalPermission] = useState("");
  const [visible, setVisible] = useState(false);
  const [showManageDependency, setShowManageDependency] = useState(false);
  const [customizeViewFields, setCustomizeViewFields] = useState({});
  const [availableFields, setAvailableFields] = useState([]);
  const [isFilterActivated, setIsFilterActivated] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState([]); // setting properly
  const [resizeMode, setResizeMode] = useState("expand");
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [selectedFields, setSelectedFields] = useState({});
  const [sortData, setSortData] = useState([]);
  const [messageContent, setMessageContent] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [showApproveDialogCPPFA, setShowApproveDialogCPPFA] = useState(false);
  const [selectedTaskApproveDialogCPPFA, setSelectedTaskApproveDialogCPPFA] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [flatFilterData, setFlatFilterData] = useState([]);
  const [rawColumns, setRawColumns] = useState([]);
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [frozenUpdated, setFrozenUpdated] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState("0px");
  const [isPmAccess, checkPMaccess] = useState(false);
  const [updatedDataFlag, setUpdatedDataFlag] = useState(false);
  const [InitialTableData, setInitialTableData] = useState([]);
  const [datatableColumnFromPega, setDatatableColumnFromPega] = useState([]);
  const [urlAccessable, setUrlAccessable] = useState("");
  const isAccessEmpty = true;
  const [pageEdited, setPageEdited] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState("");
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [checkReadWriteAccess, setUserAccess] = useState(false);
  const [hasOwnerValue, setOwnerValue] = useState(true);

  const {
    userDetails: { UserGroup, AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const User = useSelector((state) => state?.UserReducer);
  const userInformation = User.userInformation;
  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);
  const accessSecurityMatrix = usePriorityNew(location?.pathname.split("/")[1]);

  useEffect(() => {
    const fetchColumnFromPega = async () => {
      try {
        let datatableColumnFromPegaRes = await getColumnsOrder(
          userInformation?.userid,
          "projectPlanAllColumnNames",
          "NA",
          "NA"
        );
        const datatableColumnFromPegaData = _.cloneDeep(datatableColumnFromPegaRes);
        if (datatableColumnFromPegaData != null && datatableColumnFromPegaData?.length > 0) {
          setDatatableColumnFromPega(datatableColumnFromPegaData);
        } else {
          let localAllColumns = localStorage.getItem("projectPlanAllColumnNames");
          let _allColumns = JSON.parse(localAllColumns);
          if (isEmpty(_allColumns)) {
            _allColumns = ProjectService.getProjectPlanAllColumnNames();
          }
          setDatatableColumnFromPega(_allColumns);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchColumnFromPega();
    if (UserGroup?.length && userInformation?.userid && checkReadWriteAccess) {
      getProjectPlanApi();
      getRolesOwnersApi();
    }
  }, [checkReadWriteAccess, UserGroup, userInformation] //commenting to check the 1st login issue in CV
  );

  // Integrate the useUnsavedChangesWarning hook to sow warning o sidebar
  const { markDirty, markClean } = useUnsavedChangesWarning();
  // useEffect(() => {
  //   if (activeSave || projectState === "Complete") {
  //     markClean();
  //   } else {
  //     markDirty();
  //     setPageEdited(true);
  //   }
  // }, [activeSave, projectState]);

  useEffect(() => {
    markClean();
    setPageEdited(false);
  }, []);

  useEffect(() => {
    if (datatableColumnFromPega?.length > 0) {
      try {
        let _allColumns = datatableColumnFromPega;
        _allColumns = buildDefaultAllColumns(_allColumns);
        let selectedFeilds = _allColumns?.map((ele) => {
          if (ele?.field_Name === "Project_TaskID") {
            return "Task ID";
          } else if (ele?.field_Name === "Project_PredecessorID") {
            return "Predecessor";
          } else {
            return ele.field_Name;
          }
        });
        setRawColumns(selectedFeilds);
        localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(_allColumns));
        const StorageData = localStorage.getItem("customizeViewFieldsProjectPlan");
        const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
        // set initial column values if its empty
        if (
          customizeViewFieldsStorage === null ||
          customizeViewFieldsStorage?.length == 0 ||
          customizeViewFieldsStorage === undefined
        ) {
          //setAllColumns((prevItems) => prevItems.concat(_allColumns));
          setToInitialColumns(_allColumns);
        } else {
          setCustomizeViewFields(customizeViewFieldsStorage);
          getAvaiableFeilds(customizeViewFieldsStorage);
          setCustomizeColumns(_allColumns, customizeViewFieldsStorage);
        }
      } catch (error) {
        console.error("Parsing error: ", error);
      }
      childFunc.current = clearColumnWiseFilter;
    }
  }, [datatableColumnFromPega]);

  useEffect(() => {
    const isUserAccess = DMAssignee === userInformation?.userid;
    setFinalPermission(isUserAccess ? isUserAccess : accessSecurityMatrix);
  }, [DMAssignee]);

  useEffect(() => {
    const isUserAccess = isRoleMatch(AccessGroup, [
      "CapacityManager",
      "ProjectManager",
      "TaskOwner",
    ]);
    checkPMaccess(isUserAccess);
    setUserAccess(isUserAccess);
    setLoader(false);
  }, [AccessGroup]);

  useEffect(() => {
    if (urlAccessable === "Hidden") {
      setUserAccess(false);
    } else if (urlAccessable === "Read") {
      setUserAccess(true);
    } else if (urlAccessable === "Write") {
      setUserAccess(true);
    }
  }, [urlAccessable]);

  useEffect(() => {
    frozenColumn();
  }, [frozenUpdated, allColumns]);

  useEffect(() => {
    if (dropdownRef?.current) {
      setWidth(dropdownRef?.current?.offsetWidth);
    }
    if (projectData && projectData.length) {
      const flatdata = [...projectData]?.flatMap((obj) => extractData(obj));
      setFlatData(flatdata);
      const formattedData = updatedProjectPlanDesignData();
      const DesignAndInputData = formattedData?.filter(
        (data) =>
          data.GroupName !== undefined &&
          data.GroupName !== "Final Art" &&
          !data?.AWM_Task_ID?.includes("CPC_")
      );
      const hasRoleAndAssignee = (item) => {
        return item.Role && item.Role.length > 0 && item.Assignee && item.Assignee.length > 0;
      };
      const result = DesignAndInputData.every(hasRoleAndAssignee);
      const hasAllRoleOwner = checkIfUpdatedHasRoleOwner(flatdata)
      setOwnerValue(hasAllRoleOwner)
      setUpdatedDataFlag(result);
    }
  }, [projectData]);



  useEffect(() => {
    setSelectedColumnName("");
    let areObjectsEqualData = true;
    const updatedProjectData = removeEleKey(projectData);
    if (sortData?.length) {
      const column = sortData?.[0];
      const direction = sortData?.[1];
      const sortedData = _.cloneDeep(InitialTableData);
      let sortedDataSet = [];
      if (column === "Task") {
        sortedDataSet = SortAndFilterService.sortMultiLabelData(sortedData, column, direction);
      } else {
        sortedDataSet = SortAndFilterService.sortMultiColumnData(sortedData, column, direction);
      }
      areObjectsEqualData = JSON.stringify(sortedDataSet) === JSON.stringify(updatedProjectData);
    } else {
      areObjectsEqualData = JSON.stringify(InitialTableData) === JSON.stringify(updatedProjectData);
    }
    if (!areObjectsEqualData) {
      markDirty();
      setPageEdited(true);
    } else {
      markClean();
      setPageEdited(false);
    }
  }, [projectData])

  useEffect(() => {
    const flatdata = filters?.length ? filters?.flatMap((obj) => extractData(obj)) : [];
    setFlatFilterData(flatdata);
  }, [filters]);

  useEffect(() => {
    const getFlags = checkFirstTime();
    if (projectState === "Draft" && !getFlags?.firstTime) {
      setActiveSave(false);
    } else if ((projectState === "Draft" && getFlags?.firstTime) || projectState === "Active") {
      setActiveSave(true);
    }
    if (projectState === "Draft" && getFlags?.firstTime && getFlags?.activateButton) {
      setActiveFlag(false);
    }
  }, [projectState]);

  useEffect(() => {
    if (updatedDataFlag && projectState !== "Active") {
      setActiveFlag(false);
    } else if (
      !updatedDataFlag ||
      projectState === "Active" ||
      !isAccessEmpty ||
      projectData?.length === 0
    ) {
      setActiveFlag(true);
    }
  }, [updatedDataFlag]);

  const checkIsFilterApplied = () => {
    let isSelectedVal = false;
    if (selectedFields && Object.keys(selectedFields)?.length > 0) {
      isSelectedVal = Object.values(selectedFields)?.some((value) => value.length > 0);
    }
    return isSelectedVal;
  };
  useEffect(() => {
    if (isSearch || checkIsFilterApplied()) {
      setIsFilterActivated(true);
    } else {
      setIsFilterActivated(false);
    }
  }, [isSearch]);

  const setFrozenColumns = (frozenColumns, columns) => {
    //set freeze columns
    const colsData = columns?.length ? columns : allColumns;
    const frozenColumnsSet = new Set(frozenColumns);
    colsData?.forEach((item) => {
      if (frozenColumnsSet.has(item.field_Name)) {
        item.freeze = true;
      } else {
        item.freeze = false;
      }
    });
    setFrozenUpdated(!frozenUpdated);
    setAllColumns(colsData);
  };

  const setCustomizeColumns = (allColumns, customizeViewFieldsStorage) => {
    const freezedData = customizeViewFieldsStorage?.freezedColumns?.fieldsData || [];
    let selectedData = customizeViewFieldsStorage?.selectedFields?.fieldsData || [];
    if (freezedData?.length > 0) {
      selectedData = [...freezedData, ...selectedData];
    }
    setFrozenColumn([...freezedData]);
    const viewCols = getSortedCols(allColumns, freezedData, selectedData);
    setFrozenColumns(freezedData, viewCols);
  };

  const getRolesOwnersApi = async () => {
    //setLoader(true);
    let apiData = [];
    if (ProjectID) {
      const getRolesOwnersData = await getRolesOwners(ProjectID);
      if (getRolesOwnersData?.responseStatus === 200) {
        if (typeof getRolesOwnersData === "object") {
          apiData = getRolesOwnersData.rolesOwnersData;
        } else {
          apiData = [];
        }
      } else {
        apiData = [];
      }
    } else {
      apiData = [];
    }
    if (apiData?.length) {
      setRolesOwners([...apiData]);
      dispatch(setOwnerList(apiData));
    }
    //setLoader(false);
  };

  const getProjectPlanApi = async () => {
    setLoader(true);
    let restructuredData = [];
    let apiData = [];
    if (ProjectID) {
      const payLoad = {
        ArtworkAgilityPage: {
          UserId: userInformation?.userid,
          ProjectID: ProjectID,
          UserGroup: UserGroup,
        },
      };
      const getProjectPlanData = await getProjectPlan(ProjectID, payLoad);
      if (getProjectPlanData?.responseStatus === 200) {
        if (typeof getProjectPlanData === "object") {
          apiData = getProjectPlanData?.projectPlanData;
          setUrlAccessable(getProjectPlanData?.AccessType);
          dispatch(updateProjectStateAction(getProjectPlanData?.projectState));

          dispatch(SpecialProjectAction(getProjectPlanData?.specialProject));
          redirectUnallocatedLinksToArtworkStatus(
            currentUrl,
            getProjectPlanData?.specialProject,
            navigate
          );
          getProjectPlanData?.projectPlanData?.forEach((obj) => {
            if (obj.Task_Type === "Dependency Mapping") {
              setDMAssignee(obj.Assignee);
            }
          });
          setRawData(apiData);
          setProjectState(getProjectPlanData?.projectState);
        } else {
          apiData = [];
        }
      } else {
        apiData = [];
      }
    } else {
      apiData = [];
    }
    for (let i = 0; i < apiData?.length; i++) {
      const item = apiData[i];
      apiData[i] = {
        ...item,
        Buffer: item.Buffer ? String(item.Buffer) : item.Buffer,
        Remaining_Work: item.Remaining_Work ? String(item.Remaining_Work) : item.Remaining_Work,
        Days_Left:
          item.State === "In-Progress"
            ? String(getDaysLeft(item.Start_Date, item.TaskDuration))
            : "N/A",
        Start_Date: changeDateFormat(item.Start_Date),
        End_Date: changeDateFormat(item.End_Date),
        Help_Needed: item.GroupName === "Final Art" ? "N/A" : item.Help_Needed || "No",
      };
    }
    apiData && dispatch(updateProjectPlanDesignAction(apiData));
    restructuredData = apiData?.length > 0 ? getRestructuredData(apiData) : [];
    dispatch(updateProjectPlanAction(restructuredData?.projectdata));
    setExpandedKeys(restructuredData?.keysObj);
    setProjectData(_.cloneDeep(restructuredData?.projectdata));
    setInitialTableData(_.cloneDeep(restructuredData?.projectdata));
    if (apiData?.length > 100) {
      setTimeout(() => {
        setLoader(false);
      }, 1);
    } else {
      setLoader(false);
    }
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const projectNameHeader = (options, columns, col) => {
    return (
      <div className="flex-display" key={options}>
        <img
          src={filter}
          alt="Column Filter"
          key={options}
          onClick={(e) => projectNameOnClick(e, options)}
          className={
            sortData?.includes(options) ||
              selectedFields?.[options]?.[0] ||
              selectedFields?.[options]?.[0] === false ||
              (frozenCoulmns.length > 0 && frozenCoulmns?.includes(options))
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        />
        <span
          className={
            sortData?.includes(options) ||
              selectedFields?.[options]?.[0] ||
              selectedFields?.[options]?.[0] === false
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        >
          {col?.field_Name === "Project_TaskID" || col?.field_Name === "Project_PredecessorID"
            ? columns
            : options}
        </span>
      </div>
    );
  };

  const breadcrumb = (
    <div>
      <nav className="p-breadcrumb p-component ProjectPlanBreadCrum" aria-label="Breadcrumb">
        <ul>
          <li className="">
            <NavLink to={`/${url[1]}`} className="p-menuitem-link">
              <span className="p-menuitem-text">{startCase(url[1])}</span>
            </NavLink>
          </li>
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          <li className="">
            <a href="#" className="p-menuitem-link">
              <span className="p-menuitem-text">{headerName}</span>
            </a>
          </li>
          <li>
            {!loader &&
              ProjectID === rawData?.[0]?.AWM_Project_ID &&
              rawData?.[0]?.Project_Name && (
                <div className="project-name">{!loader && rawData?.[0]?.Project_Name}</div>
              )}
          </li>
        </ul>
      </nav>
    </div>
  );

  // needs to pass in Custom Breadcrum
  const items = [
    { label: _.startCase(url[1]), url: `/${url[1]}` },
    { label: headerName, url: "" },
    { label: !loader && rawData?.[0]?.Project_Name, url: "" },
  ];

  const addAllColumsFromCustomizeView = (selectedDataList) => {
    const allInitialColumns = buildDefaultAllColumns(ProjectService.getProjectPlanAllColumnNames());
    let tempCurrentColumnsname = _.cloneDeep(allColumns);

    const missingColumn = selectedDataList?.filter(item =>
      !tempCurrentColumnsname?.some(obj => obj?.field_Name === item)
    );
    const itemsToAdd = allInitialColumns?.filter(obj => missingColumn?.includes(obj?.field_Name));
    if (itemsToAdd?.length > 0) {
      const finalArray = [...tempCurrentColumnsname, ...itemsToAdd]
      const reorderedArray = selectedDataList?.map(ele =>
        finalArray?.find(obj => obj?.field_Name === ele)
      )
      setAllColumns(reorderedArray);
    }
  }

  const handleCustomizedView = async (data) => {
    if (data !== undefined && data !== null && data?.length !== 0) {
      setLoader(true); //loader is added to set the customized columns to table
      const parsedData =
        data?.selectedFields?.fieldsData?.length > 0
          ? data?.selectedFields?.fieldsData
          : data && JSON.parse(data);
      const freezedData = parsedData?.freezedColumns?.fieldsData || [];
      const selectedData = parsedData?.selectedFields?.fieldsData || [];
      localStorage.setItem("customizeViewFieldsProjectPlan", JSON.stringify(parsedData));
      await setColumnsOrder(
        userInformation?.userid,
        "customizeViewFieldsProjectPlan",
        "NA",
        "NA",
        false,
        JSON.stringify(parsedData)
      );
      const selectedDataVal = AcpService?.removeCommonValues(selectedData, freezedData);
      let selectedDataList = selectedDataVal;
      if (freezedData?.length > 0) {
        selectedDataList = [...freezedData, ...selectedDataVal];
      }
      const viewCols = getSortedCols(allColumns, freezedData, selectedDataList);
      setFrozenColumns(freezedData, viewCols);
      setCustomizeViewFields(parsedData);
      getAvaiableFeilds(parsedData);
      freezedData?.length > 0 && setFrozenColumn([...freezedData]);
      freezedData?.length == 0 && setFrozenColumn([]); //when you drag from frozen to selected reset the state
      allColumns.length !== selectedDataList.length && addAllColumsFromCustomizeView(selectedDataList) // when u move back from available feilds to selected
      setLoader(false);
      if (selectedDataList.length < 7) {
        setResizeMode("fit");
      } else {
        setResizeMode("expand");
      }
    }
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      projectData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current?.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentProjectPlan");
    }
    if (filterArray?.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected?.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithUniqueObjects
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        let { Task, ...newObj } = uniqueObj;
        let filteredData2 = [];
        let filteredData = [];
        if (Task?.length) {
          const Task2 = {
            Task: Task,
          };
          filteredData = SortAndFilterService.applyMutilabelFilter(projectData, Task2, filters);
        }
        if (Object.entries(newObj)?.length) {
          const dataToFilter = filteredData?.length ? filteredData : projectData;
          const updatedpegadata = SortAndFilterService.updateAssigneeToOwner(dataToFilter);
          filteredData2 = SortAndFilterService.multipleColumnFilter(
            updatedpegadata,
            newObj,
            filters
          );
        }
        const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
        setSelectedFields(uniqueObj);
        if (finalFilteredData?.length && sortData?.length) {
          onSort(sortData?.[0], sortData?.[1], finalFilteredData);
        } else {
          setFilters(finalFilteredData);
          selectedColumnList.current = finalFilteredData;
        }
      } else {
        setFilters([]);
        setSelectedFields({});
        selectedFieldsRef.current = [];
        selectedColumnList.current = [];
      }
    } else {
      setFilters([]);
      setSelectedFields({});
      selectedFieldsRef.current = [];
      selectedColumnList.current = [];
    }
  };

  // body template of columns
  const elementTemplate = (options, rowData) => {
    return (
      <ElementTemplate
        options={options}
        rowData={rowData}
        sortData={sortData}
        filterData={filters}
        ProjectID={ProjectID}
        isDisabled={!isPmAccess || urlAccessable === "Read" || projectState === "Complete"}
        pageEdited={pageEdited}
        dropdownRef={dropdownRef}
        RolesOwners={RolesOwners}
        updateFields={handleFieldUpdate}
        width={width}
        handleDialog={handleApproveDialogCPPFA}
        handleInfoIcon={handleInfoIconClick}
        setDropDownMatch={setDropDownMatch}
        setOwnerValue={setOwnerValue}
      />
    );
  };

  const handleFieldUpdate = (rowData, value, ele) => {
    const clonedProjectData = [...projectData];
    if (ele === "Start_Date" || ele === "TaskDuration") {
      const updatedData = updateProjectPlanData(clonedProjectData, rowData, value);
      setTimeout(() => {
        setProjectData(updatedData);
      }, 1);
      setActiveSave(false);
    } else {
      const updatedData = updateProjectPlanRoleOwner(clonedProjectData, rowData, value, ele);
      setProjectData(updatedData);
      setActiveSave(false);
    }
  };

  const handleInfoIconClick = (message, reason) => {
    setMessageContent([message, reason]);
    setShowDialog(true);
  };

  const handleApproveDialogCPPFA = (options) => {
    // 3109 security matrix
    const userAccessPayLoad = {
      ArtworkAgilityPage: {
        UserId: userInformation?.userid,
        ProjectID: ProjectID,
        UserGroup: UserGroup,
      },
    };
    setShowApproveDialogCPPFA(true);
    let task = { TaskID: options.code, ProjectID: ProjectID };
    setSelectedTaskApproveDialogCPPFA(task);
    dispatch(getTaskDetails(options.code, ProjectID, userAccessPayLoad));
  };

  const getSortedCols = (allColumns, freezedData, selectedData) => {
    let tempallColumnsname = _.cloneDeep(allColumns);
    tempallColumnsname?.forEach((ele) => {
      if (freezedData?.some((str) => ele.field_Name === str)) {
        ele["freeze"] = true;
      } else {
        ele["freeze"] = false;
      }
    });
    const alteredColumns = tempallColumnsname?.filter((column) => {
      if (selectedData.some((str) => column.field_Name === str)) {
        return column;
      }
    });
    const sortedalteredColumns = sortBasedOnSelectedValue(alteredColumns, selectedData);
    sortedalteredColumns?.forEach((item) => {
      if (!item?.column_Name) {
        item.column_Name = item.field_Name;
      }
    });
    return sortedalteredColumns;
  };

  const dynamicColumns = () => {
    if (allColumns?.length) {
      return allColumns?.map((ele, i) => {
        return (
          <Column
            key={ele.field_Name}
            field={ele.field_Name === "Owner" ? "Assignee" : ele.field_Name}
            filterField={ele.field_Name}
            filterMatchMode="contains"
            filter={isSearch}
            filterPlaceholder={ele.field_Name}
            header={projectNameHeader(ele.field_Name, ele.column_Name, ele)}
            expander={ele.field_Name === "Task"}
            columnKey={ele.field_Name || i}
            frozen={ele.freeze}
            alignFrozen="left"
            className={
              ele?.field_Name === "Task" && ele.freeze
                ? "task-field-width fontBoldcolor"
                : ele?.field_Name === "Task"
                  ? "task-field-width"
                  : ele?.field_Name === "Role" && !ele.freeze
                    ? "role-field-width"
                    : ele?.field_Name === "Project_TaskID"
                      ? "customized-min-width"
                      : ele?.field_Name === "Buffer" ||
                        ele?.field_Name === "Days_Left" ||
                        ele?.field_Name === "End_Date" ||
                        ele?.field_Name === "State"
                        ? "customized-min-width"
                        : ele?.field_Name === "TaskDuration" ||
                          ele?.field_Name === "Help_Needed" ||
                          ele?.field_Name === "Project_PredecessorID"
                          ? "taskDuration-min-width"
                          : ele.freeze
                            ? "fontBoldcolor"
                            : "cursorMove non-task-width"
            }
            showFilterMenu={false}
            body={elementTemplate}
            style={{ width: ele.width }}
          />
        );
      });
    }
  };

  const checkFirstTime = () => {
    const designInputData = rawData?.filter(
      (data) => data.GroupName !== "Final Art" && !data?.AWM_Task_ID?.includes("CPC_")
    );
    const hasRoleAndAssignee = (item) => {
      return item.Role && item.Role.length > 0 && item.Assignee && item.Assignee.length > 0;
    };
    const activateButton = designInputData?.every(hasRoleAndAssignee);

    const firstTime = designInputData?.some((item) => item.Assignee !== "" || item.Role !== "");

    return {
      activateButton: activateButton,
      firstTime: firstTime,
    };
  };

  const updatedProjectPlanDesignData = () => {
    const formattedData = [];
    const stack = [...projectData]; // Initialize stack with root elements

    while (stack.length > 0) {
      const node = stack.pop(); // Pop the last element for depth-first traversal
      const { children = [], data } = node;

      if (data) {
        formattedData.push({
          ...data,
          AWM_Project_ID: rawData?.[0]?.AWM_Project_ID,
          AWM_Task_ID: node.code,
          Task_Name: data.Task,
          Assignee: data.Owner || data.Assignee,
        });
      }

      // Push children to the stack in reverse order for proper traversal
      for (let i = children.length - 1; i >= 0; i--) {
        stack.push(children[i]);
      }
    }
    return formattedData;
  };

  const onSave = async () => {
    setLoader(true);
    let updatedData = [];
    const formattedData = updatedProjectPlanDesignData();
    const firstTime = checkFirstTime();
    const updatedSaveData = saveProjectPlanData(
      updatedData,
      false,
      formattedData,
      rawData,
      projectState,
      firstTime?.firstTime
    );

    if (updatedSaveData.length !== 0) {
      const formData = {
        ArtworkAgilityProjects: updatedSaveData,
      };
      //Making the Save proofsocpe API and clearing the URL values in case assignee changes
      try {
        const tasksToProcess = updatedSaveData?.filter(
          (task) => task.AWM_Task_ID.startsWith("ARDT") || task.AWM_Task_ID.startsWith("ACIC")
        );
        if (tasksToProcess && tasksToProcess.length > 0) {
          for (const task of tasksToProcess) {
            const { AWM_Task_ID, Assignee } = task;
            // Find the corresponding task in projectPlanDesign based on AWM_Task_ID
            const correspondingTask = rawData?.find(
              (designTask) => designTask.AWM_Task_ID === AWM_Task_ID
            );

            // Check if corresponding task is found
            if (correspondingTask) {
              // Fetch Assignee value from projectPlanDesign
              const projectPlanDesignAssignee = correspondingTask?.Assignee;
              if (correspondingTask?.State === "In-Progress") {
                if (projectPlanDesignAssignee !== task.Assignee) {
                  // Check if Assignee is different from the Assignee in projectPlanDesign
                  const requestData = {
                    AWMProjectID: ProjectID,
                    AWMTaskID: AWM_Task_ID,
                    PFFileURL: "",
                    ViewPFFileURL: "",
                    ApprovalID: "",
                    Assignee: Assignee,
                    // Use Assignee from projectPlanDesign
                  };
                  try {
                    await saveProofscopeURL(requestData);
                  } catch (error) {
                    console.error("Error calling API for task:", AWM_Task_ID, error);
                  }
                }
              }
            }
          }
          await saveProjectPlanAction(formData, ProjectID);
          getProjectPlanApi();
          setActiveSave(true);
          updateActivateButton();
        } else {
          await saveProjectPlanAction(formData, ProjectID);
          getProjectPlanApi();
          setActiveSave(true);
          updateActivateButton();
        }
        markClean();
        setPageEdited(false);
        showBackgroundProcess(toast);
      } catch (error) {
        console.error("Error processing formData:", error);
      }
    }
  };

  const updateActivateButton = () => {
    if (projectState === "Draft") {
      setActiveFlag(false);
    } else {
      setActiveFlag(true);
    }
  };

  const activate = async () => {
    setLoader(true);
    setActiveFlag(true);
    let updatedData = [];
    const formattedData = updatedProjectPlanDesignData();
    const updatedSaveData = saveProjectPlanData(
      updatedData,
      true,
      formattedData,
      rawData,
      projectState
    );

    if (updatedSaveData.length !== 0) {
      const formData = {
        ArtworkAgilityProjects: updatedSaveData,
      };
      await activateProjectPlan(formData, ProjectID);
      //await createNewAzureFolder(rawData?.[0]?.Project_Name, ProjectID, rawData?.[0]?.BU);
      getProjectPlanApi();
      await toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Project activated successfully!",
        life: 5000,
      });
      setActiveSave(true);
      setActiveFlag(true);
      showBackgroundProcess(toast);
    }
  };

  const getAllColumnNames = () => {
    let localAllColumns = localStorage.getItem("projectPlanAllColumnNames");
    let _allColumns = JSON.parse(localAllColumns);
    if (isEmpty(_allColumns)) {
      _allColumns = ProjectService.getProjectPlanAllColumnNames();
    }
    return _allColumns;
  };

  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "Freezed Fields",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "Selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated;
  };

  const setFreezedColumns = (allcolumnsVal, freezedColumns) => {
    allcolumnsVal?.forEach((item) => {
      if (freezedColumns?.includes(item?.field_Name)) {
        item.freeze = true;
      } else {
        item.freeze = false;
      }
    });
    setAllColumns(allcolumnsVal);
  };

  const setToInitialColumns = async (allcolumnsVal) => {
    let selectedFeilds = getAllColumnNames();
    selectedFeilds = selectedFeilds?.map((ele) => {
      return ele.field_Name;
    });
    let customizeViewColumnFromPega = await getColumnsOrder(
      userInformation?.userid,
      "customizeViewFieldsProjectPlan",
      "NA",
      "NA"
    );

    if (customizeViewColumnFromPega != null && customizeViewColumnFromPega != "") {
      setCustomizeViewFields(customizeViewColumnFromPega);
      getAvaiableFeilds(customizeViewColumnFromPega);
      localStorage.setItem(
        "customizeViewFieldsProjectPlan",
        JSON.stringify(customizeViewColumnFromPega)
      );
      const freezedColumns = customizeViewColumnFromPega?.freezedColumns?.fieldsData || [];
      setFrozenColumn(freezedColumns);
      //as we are getting reference error while setting the state
      if (allcolumnsVal?.length > 0) setFreezedColumns(allcolumnsVal, freezedColumns);
    } else {
      const customizeViewFieldsUpdated = formCustomizeObject([], [], selectedFeilds);
      setCustomizeViewFields(customizeViewFieldsUpdated);
      localStorage.setItem(
        "customizeViewFieldsProjectPlan",
        JSON.stringify(customizeViewFieldsUpdated)
      );
      await setColumnsOrder(
        userInformation?.userid,
        "customizeViewFieldsProjectPlan",
        "NA",
        "NA",
        false,
        JSON.stringify(customizeViewFieldsUpdated)
      );
    }
  };

  const buildDefaultAllColumns = (data) => {
    const formatted = data?.map((ele) => {
      if (ele) {
        let width =
          ele["field_Name"] === "Task"
            ? 400
            : ele.field_Name === "State" ||
              ele.field_Name === "End_Date" ||
              ele.field_Name === "Days_Left"
              ? 100
              : ele.field_Name === "TaskDuration"
                ? 120
                : (ele.field_Name === "Owner" || ele.field_Name === "Role")? 180 : 140;
        if (ele) {
          ele["sortZtoA"] = false;
          ele["sortAtoZ"] = false;
          ele["width"] = width;
          ele["reorder"] = false;
          ele["freeze"] = data.freeze || false;
        }
      }
      return ele;
    });
    return formatted;
  };

  const onClickClearFilter = (val) => {
    const _allColumns = buildDefaultAllColumns(ProjectService.getProjectPlanAllColumnNames());
    if(val!=="clearFilterAndSearch"){
      localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(_allColumns));
      localStorage.setItem("projectPlanSelectedColumnNames", null);
      if(val!=="reset"){
        setSortData([]);
        clearColumnWiseFilter();
      }
    }
    if (val === undefined || val !== "MassUpdate Clicked") {
      setFrozenUpdated(!frozenUpdated);
      setToInitialColumns([]);
    }
    if (val === "reset") {
      setFrozenColumn([]);
      setAllColumns(_allColumns);
      setCustomizeViewFields([]);
      setAvailableFields([]);
    } else {
      selectedColumnList.current = [];
      selectedFieldsRef.current = [];
      setFieldUpdated(!fieldUpdated);
      setIsFilterActivated(false);      
      setSelectedFields({});
      setVisible(false);
      setFilters([]);
    }
  };
  
  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const handleChildClick = (isClicked) => {
    if (!isClicked) {
      onClickClearFilter("clearFilterAndSearch")
      isSearchSet(false);
      setResetKey((prev) => prev + 1);
    }
  };

  const handleExapandCollapseAllButton = () => {
    if (expandedKeys.length === 0) {
      const allkeys = handleExpandAll(projectData);
      setExpandedKeys(allkeys);
    } else {
      setExpandedKeys([]);
    }
    setResetKey((prev) => prev + 1);
  };

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length ? filterData : filters?.length ? filters : projectData;
    let sortedDataSet = [];
    if (column === "Task") {
      sortedDataSet = SortAndFilterService.sortMultiLabelData(sortedData, column, direction);
    } else {
      sortedDataSet = SortAndFilterService.sortMultiColumnData(sortedData, column, direction);
    }
    setSortData([column, direction]);
    localStorage.setItem("sortingDataProjectPlanList", JSON.stringify(true));
    if (filterData?.length || filters?.length) {
      setFilters(sortedDataSet);
      selectedColumnList.current = sortedDataSet;
    } else {
      setProjectData(sortedDataSet);
    }
  };

  const clearColumnWiseFilter = () => {
    let selectedColumn = selectedColumnName;
    const selectedFields2 = _.cloneDeep(selectedFields);
    const uniqueObj = AcpCommonService.getUniqueValuesObject(selectedFields2);
    const _selectedFields = AcpService.emptyProperty(uniqueObj, selectedColumn);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentProjectPlanList");
    }
    let { Task, ...newObj } = __selectedFields;
    let filteredData2 = [];
    let filteredData = [];
    if (Task?.length) {
      const Task2 = {
        Task: Task,
      };
      filteredData = SortAndFilterService.applyMutilabelFilter(projectData, Task2, filters);
    }
    if (Object.entries(newObj)?.length > 0) {
      filteredData2 = SortAndFilterService.multipleColumnFilter(
        filteredData?.length ? filteredData : projectData,
        newObj,
        filters
      );
    }
    if (sortData && sortData?.length && sortData[0] === selectedColumn) {
      localStorage.removeItem("sortingDataProjectPlanList");
      setSortData([]);
    }
    const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
    if (finalFilteredData?.length > 0 && Object.keys(__selectedFields)?.length > 0) {
      setFilters(finalFilteredData);
    } else {
      setFilters([]);
      const finalInitialData = _.cloneDeep(InitialTableData);
      selectedColumnList.current = finalInitialData;
      selectedFieldsRef.current = [];
      setProjectData(finalInitialData);
    }
  };

  const frozenColumn = () => {
    const numFrozenColumns = allColumns?.filter((col) => col.freeze).length;
    let totalFrozenWidth = 0;

    if (numFrozenColumns > 0) {
      allColumns?.forEach((col) => {
        if (col.freeze) {
          totalFrozenWidth += col.field_Name === "Task" ? 280 : 200;
        }
      });
    }
    if (totalFrozenWidth > 0) {
      setFrozenWidth(`${totalFrozenWidth}px`);
    } else {
      setFrozenWidth(`0px`);
    }
  };

  const onColumnResizeEnd = (event) => {
    const _allColumns = allColumns?.map((list) => {
      if (event.column.props.field === list.field_Name) {
        list.width = event.element.offsetWidth;
      }
      return list;
    });
    localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(_allColumns));
    //setAllSelectedColumns(_allColumns);
    setFieldUpdated(!fieldUpdated);
  };

  const storeReorderedColumns = (e) => {
    let columnNamesJson = localStorage.getItem("projectPlanAllColumnNames");
    const projectColumnName = JSON.parse(columnNamesJson);
    if (projectColumnName?.length > 0) {
      const dragColumnName = projectColumnName[e?.dragIndex];
      const index = projectColumnName.indexOf(dragColumnName);
      if (index > -1) {
        // only splice array when item is found
        projectColumnName.splice(index, 1); // 2nd parameter means remove one item only
        projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
      }
      projectColumnName.forEach((ele, index) => {
        ele.reorder = ele.field_Name === dragColumnName.field_Name;
        ele.sequence = index + 1;
      });
      localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(projectColumnName));
      setAllColumns(projectColumnName);
    }
  };

  const getUpdatedAllColumnFormat = (column) => {
    const finalData = column?.map((el) => {
      return {
        width: el.width,
        Sequence: "",
        freeze: el.freeze,
        Field_Name: el.field_Name,
        reorder: false,
        Column_Name: el.field_Name,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });
    return finalData;
  };

  // on Click of Rework section from Project Paln page
  // it will first check if there is no change in project plan page
  // then it will do normal redirection as earlier
  // but if there is any change it will ShowWarningDialog  and
  // it will setPendingNavigation, which value will be used later in confirmNavigation
  const handleClickRework = () => {
    if (pageEdited) {
      setShowWarningDialog(true);
      setPendingNavigation(`${currentUrl}/rework`);
    } else {
      markClean();
      setShowWarningDialog(false);
      navigate(`${currentUrl}/rework`);
    }
  };

  // if page is not edited then will work as earlier redirect to comes from page
  // but if any anthing altered in page then ShowWarningDialog  will be true
  // and setPendingNavigation will seve temp url which will be used on confirm
  const handleCancel = () => {
    if (pageEdited) {
      setShowWarningDialog(true);
      setPendingNavigation(`/${url[1]}`);
    } else {
      markClean();
      navigate(`/${url[1]}`);
      setShowWarningDialog(false);
    }
  };

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowWarningDialog(false);
  };
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowWarningDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    setShowWarningDialog(false);
    navigate(pendingNavigation);
    setPendingNavigation("");
  };

  const getAvaiableFeilds = (customizeViewFields) => {
    const availableData = customizeViewFields?.availableFields?.fieldsData;
    let availableDataList = [];
    if (availableData?.length > 0) {
      availableData.forEach((item) => {
        const innerObj = {
          Field_Name: item,
        };
        availableDataList.push(innerObj);
      });
      setAvailableFields(availableDataList);
    }
  };

  return (
    <div className="projectSetupParent">
      {loader ? (
        <LoadingOverlay className="h-100" active={loader} spinner text="" />
      ) : checkReadWriteAccess ? (
        <div className="projectSetupParent project-plan-wrapper newdiv">
          <Toast ref={toast} />
          <div className="breadCrumbParent">
            <div className="row">
              <div className="col">
                {/* {breadcrumb} */}

                <CustomBreadCrum
                  items={items}
                  iconColorBlack={true}
                  lastLabel={"projectPlan"}
                  lastChildSecondChild={false}
                />
              </div>
              <div className="col custom-style-container" style={{ display: "flex", gap: "6px" }}>
                {showManageDependency && (
                  <ManageDependency
                    showManageDependency={showManageDependency}
                    setShowManageDependency={setShowManageDependency}
                    ProjectID={ProjectID}
                    setPageEdited={setPageEdited}
                    getApiParentPage={getProjectPlanApi}
                    Assignee={DMAssignee}
                    finalPermission={finalPermission}
                    isProjectComplete={projectState === "Complete"}
                  />
                )}
                {visible && (
                  <CustomizeView
                    availableFields={availableFields || []}
                    showTaskDialog={visible}
                    headerName={"Project Plan"}
                    onClose={() => setVisible(false)}
                    customizeViewFields={customizeViewFields}
                    setCustomizeViewFields={handleCustomizedView}
                    resetToPgDefault={() => onClickClearFilter("reset")}
                    allColumns={getUpdatedAllColumnFormat(
                      ProjectService.getProjectPlanAllColumnNames()
                    )}
                    setResetKey={setResetKey}
                  />
                )}
                <ProjectListHeader
                  header=""
                  clearFilter={onClickClearFilter}
                  setVisible={() => { }}
                  saveSettings={() => { }}
                  clearFilters={onClickClearFilter}
                  onSearchClick={onSearchClick}
                  isFilterEnabled={filters?.length > 0 || isFilterActivated}
                  allData={flatData}
                  headers={rawColumns}
                  filterFLag={false}
                  CustomizeViewFlag={true}
                  ResetToDefaultFlag={true}
                  projectPlanView={true}
                  handleExapandCollapseAllButton={handleExapandCollapseAllButton}
                  expandedKeys={expandedKeys}
                  filename="Project_Plan_Records"
                  selected={flatFilterData}
                  onChildClick={handleChildClick}
                />
                <Button
                  onClick={handleClickRework}
                  // onClick={() => navigate(`${currentUrl}/rework`)}
                  disabled={
                    projectState === "Complete"
                    // ||
                    // urlAccessable === "Read" ||
                    // urlAccessable === "Hidden"
                  }
                >
                  Rework
                </Button>
                <Button
                  variant="secondary"
                  className="button-layout"
                  onClick={() => setVisible(true)}
                >
                  Customize View
                </Button>
                <ActionButton
                  showManageDependency={showManageDependency}
                  setShowManageDependency={setShowManageDependency}
                  getProjectPlanApi={getProjectPlanApi}
                  clearFilters={onClickClearFilter}
                  pageEdited={pageEdited}
                  setPageEdited={setPageEdited}
                  disabled={
                    projectState === "Complete" ||
                    urlAccessable === "Read" ||
                    urlAccessable === "Hidden"
                  }
                />
              </div>
            </div>
          </div>
          {showApproveDialogCPPFA && (
            <CPPFA
              onClose={() => setShowApproveDialogCPPFA(!showApproveDialogCPPFA)}
              showTaskDialog={showApproveDialogCPPFA}
              selectedTaskData={selectedTaskApproveDialogCPPFA}
              pegadata={[]}
              isProjectComplete={projectState === "Complete"}
              getProjectPlanApi={getProjectPlanApi}
              TaskDetailsData={TaskDetailsData}
              userInformation={userInformation}
            />
          )}
          <LoadingOverlay className="h-100" active={loader} spinner text="">
            <div className="projectPlanTable myProjectAnddAllProjectList">
              <ACPFilter
                op={op}
                onSort={onSort}
                isTreeTable={true}
                sortData={sortData}
                pegadata={projectData}
                setFilters={setFilters}
                setSortData={setSortData}
                projectData={flatData}
                saveSettings={() => { }}
                generateCustomOptions={true}
                pageName={"ProjectPlanLists"}
                frozenCoulmns={[]} //2805
                selectedFields={selectedFields}
                selectAllColumnNotIncluded={true}
                selectedColumnName={selectedColumnName}
                onGlobalFilterChange={onGlobalFilterChange}
                clearColumnWiseFilter={clearColumnWiseFilter}
              />
              {!isEmpty(projectData) && !isEmpty(allColumns) && (
                <TreeTable
                  resizableColumns
                  reorderableColumns
                  scrollable
                  expanded
                  columnResizeMode={"expand"}
                  frozenWidth={frozenWidth}
                  onColumnResizeEnd={onColumnResizeEnd}
                  onColReorder={storeReorderedColumns}
                  expandedKeys={expandedKeys}
                  onToggle={(e) => setExpandedKeys(e.value)}
                  tableStyle={{ minWidth: "50rem" }}
                  value={filters.length ? filters : projectData}
                  className={
                    isSearch ? "searchWithTreeTable textAlignTreeTable" : "textAlignTreeTable"
                  }
                  key={resetKey}
                >
                  {dynamicColumns()}
                </TreeTable>
              )}
            </div>
          </LoadingOverlay>
          {showDialog && (
            <Dialog
              visible={showDialog}
              onHide={() => setShowDialog(false)}
              header="Help Needed"
              modal
              className="MassDailog"
            >
              <div>
                <span className="helpneed_dialog">Reason:</span>
                <p className="mb-5">{messageContent[1] === "undefined" ? "" : messageContent[1]}</p>
                <span className="helpneed_dialog">Comments:</span>
                <p className="mb-5">{messageContent[0] === "undefined" ? "" : messageContent[0]}</p>
              </div>
            </Dialog>
          )}
          <div className="form-buttons" style={{ background: "#FAFAFA" }}>
            <Button
              className={!isAccessEmpty ? "btn btn-disabled" : "button-layout"}
              variant="secondary"
              // onClick={() => {
              //   navigate(`/${url[1]}`);
              // }}
              onClick={handleCancel}
              disabled={!isAccessEmpty}
            >
              Cancel
            </Button>

            <Button
              className={activeSave ? "btn btn-disabled" : "button-layout"}
              variant="secondary"
              onClick={onSave}
              // disabled={activeSave || projectState === "Complete" || !dropDownMatch || !hasOwnerValue}
              disabled={
                pageEdited
                  ? activeSave || projectState === "Complete" || !dropDownMatch || !hasOwnerValue
                  : true
              }
            >
              Save
            </Button>
            <Button
              className="button-layout"
              variant="primary"
              onClick={activate}
              disabled={
                loader ||
                (projectData && Object.keys(projectData).length === 0) ||
                activeFlag ||
                projectState === "Complete" ||
                !dropDownMatch ||
                !hasOwnerValue
              }
            >
              Activate
            </Button>
          </div>
          {/* being used to show the warning msg */}
          <UnsavedChangesDialog
            visible={showWarningDialog}
            onConfirm={confirmNavigation}
            onCancel={cancelNavigation}
          />
        </div>
      ) : (
        <UnAuthorizedComponent nowrapper={true} />
      )}
      <UnsavedChangesDialog
        visible={showWarningDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </div>
  );
};
export default ProjectPlan;
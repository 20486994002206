import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AcpService } from "../../../service/ACPService";
import { fetchInternalCapacityData } from "../../../apis/acpApi";
import { transformTasks } from "../../../service/DashboardService";
import CustomInput from "../../common/CustomInput";

function InternalCapacityTable({
  setTableEdited,
  selectedMonth,
  enableEdit,
  setEditedRows,
  userGroup,
  setLoader,
  loadTable,
}) {
  const tableHeaders = ["Calendar Week", "Date", "No. of Active POA's", "No. of Kicked off POA's"];
  const [tableData, setTableData] = useState([]);
  const [POAData, setPOAData] = useState([]);

  useEffect(() => {
    let yearMonth = AcpService.formatDateToYearMonth(selectedMonth);
    let cwList = AcpService.getWeekRangesForMonth(selectedMonth, setTableData);
    setTableEdited(false);
    setPOAData([]);
    setLoader(true);
    getInternalCapacityData(yearMonth, cwList, userGroup);
  }, [selectedMonth, userGroup, loadTable]);

  const getInternalCapacityData = async (yearMonth, cwList, userGroup) => {
    let payload = {
      ManageInternalCapacity: {
        MonthYear: yearMonth,
        CWList: cwList,
        UserGroup: userGroup,
      },
    };

    fetchInternalCapacityData(payload).then((data) => {
      setLoader(false);
      let _data = data?.InternalCapacityList;
      if (_data?.length) {
        const transformedTasks = transformTasks(_data, selectedMonth);
        setPOAData(transformedTasks);
      } else {
        const transformedTasks = transformTasks([], selectedMonth);
        setPOAData(transformedTasks);
      }
    });
  };

  useEffect(() => {
    let editedRows = [];
    POAData?.forEach((task, inx) => {
      if (task?.KickedIsEdited) {
        let local = {};
        local["CalendarWeek"] = task["CalendarWeek"];
        local["Task"] = "Kicked POA's";
        local["Capacity"] = task["KickedPOA"];
        local["MonthYear"] = AcpService.formatDateToYearMonth(selectedMonth);
        // 3239 - Adding Start & End Date in the API
        local["StartDate"] = AcpService.changeDateFromISTtoUTC(tableData[inx]["dateS"]);
        local["EndDate"] = AcpService.changeDateFromISTtoUTC(tableData[inx]["dateE"]);
        editedRows.push(local);
      }
      if (task?.ActiveIsEdited) {
        let local = {};
        local["CalendarWeek"] = task["CalendarWeek"] === "CW 53" ? "CW 01" : task["CalendarWeek"];
        local["Task"] = "Active POA's";
        local["Capacity"] = task["ActivePOA"];
        local["MonthYear"] = AcpService.formatDateToYearMonth(selectedMonth);
        // 3239 - Adding Start & End Date in the API

        local["StartDate"] = AcpService.changeDateFromISTtoUTC(tableData[inx]["dateS"]);
        local["EndDate"] = AcpService.changeDateFromISTtoUTC(tableData[inx]["dateE"]);
        editedRows.push(local);
      }
    });
    setEditedRows(editedRows);
    const updatedRows = POAData?.filter(el=>el.ActiveIsEdited === true);
    if(updatedRows?.length){
      setTableEdited(true);
    }else{
      setTableEdited(false);
    }
  }, [POAData]);

  const handleChangePOA = (e, fieldType, options, rowData, removeUpdate, InitialValue) =>{
    const value = e?.target?.value ?? null;
    const updatedValue = value ? value : 0;

    if (!/^[0-9]*$/.test(updatedValue)) return;
    let _taskData = [...POAData];
    _taskData[options?.rowIndex][fieldType] = Number(updatedValue);
    _taskData[options?.rowIndex]["ActiveIsEdited"] = InitialValue == updatedValue ? false :true;
    rowData["ActiveIsEdited"] = InitialValue == updatedValue ? false : true;
    setPOAData(_taskData);
    // setTableEdited(InitialValue == updatedValue ? false : true);
  }

  const tableBody = (rowData, options) => {
    return (
      <p>
        {options.field === "Calendar Week"
          ? "CW" +
            (tableData[options.rowIndex]["week"] < 10
              ? "0" + tableData[options.rowIndex]["week"]
              : tableData[options.rowIndex]["week"])
          : options.field === "Date" &&
            AcpService.convertToShortDateFormat(tableData[options.rowIndex]["dateS"], "DD-MMM") +
              " to " +
              AcpService.convertToShortDateFormat(tableData[options.rowIndex]["dateE"], "DD-MMM")}
        {options.field === "No. of Active POA's" ? (
          <>
          {/* <input
            key={options.rowIndex + "_key"}
            disabled={enableEdit}
            className={options.isSelected ? "cap-table-input edited" : "cap-table-input"}
            contentEditable
            value={POAData.length > 0 ? POAData[options?.rowIndex]?.["ActivePOA"] : 0}
            onChange={(e) => {
              if (!/^[0-9]*$/.test(e.target.value)) return;
              let _taskData = [...POAData];
              _taskData[options?.rowIndex]["ActivePOA"] = Number(e.target.value);
              _taskData[options?.rowIndex]["ActiveIsEdited"] = true;
              rowData["ActiveIsEdited"] = true;
              setTableEdited(true);
              setPOAData(_taskData);
            }}
          ></input> */}
          <CustomInput
            rowData={rowData}
            options={options}
            field={options.field}
            type="EditableNumber"
            disabled={enableEdit}
            contentEditable={true}
            fieldType={"ActivePOA"}
            onChange={handleChangePOA}
            key={options.rowIndex + "_key"}
            value={POAData?.length > 0 ? POAData[options?.rowIndex]?.["ActivePOA"] : 0}
            innerClassName={options.isSelected ? "cap-table-input edited" : "cap-table-input"}
          />
          </>
        ) : options.field === "No. of Kicked off POA's" ? (
          <>
          {/* <input
            key={options.rowIndex + "_key"}
            disabled={enableEdit}
            className={options.isSelected ? "cap-table-input edited" : "cap-table-input"}
            contentEditable
            value={POAData.length > 0 ? POAData[options?.rowIndex]?.["KickedPOA"] : 0}
            onChange={(e) => {
              if (!/^[0-9]*$/.test(e.target.value)) return;
              let _taskData = [...POAData];
              _taskData[options.rowIndex]["KickedPOA"] = Number(e.target.value);
              _taskData[options.rowIndex]["KickedIsEdited"] = true;
              rowData["KickedIsEdited"] = true;
              setTableEdited(true);
              setPOAData(_taskData);
            }}
          ></input> */}
          <CustomInput
            rowData={rowData}
            options={options}
            field={options.field}
            type="EditableNumber"
            disabled={enableEdit}
            contentEditable={true}
            fieldType={"KickedPOA"}
            onChange={handleChangePOA}
            key={options.rowIndex + "_key"}
            value={POAData?.length > 0 ? POAData[options?.rowIndex]?.["KickedPOA"] : 0}
            innerClassName={options.isSelected ? "cap-table-input edited" : "cap-table-input"}
          />
          </>
        ) : (
          <></>
        )}
      </p>
    );
  };

  const generateColumns = (rowData, options) => {
    let headers = [];
    tableHeaders?.forEach((el, inx) => {
      headers.push(
        <Column
          key={inx + "_col"}
          field={el}
          frozen
          body={tableBody}
          alignFrozen="top"
          header={el}
          className="tableSubHeader columnDataText"
        />
      );
    });
    return headers;
  };

  return (
    <div>
      <div className="internal-cap-main external-cap-main">
        {" "}
        <DataTable
          scrollable
          value={tableData}
          tableStyle={{
            width: "max-content",
            minWidth: "100%",
          }}
          rowClassName={(rowData) =>
            rowData?.ActiveIsEdited || rowData?.KickedIsEdited ? "highlight-row" : "normal-row"
          }
        >
          {generateColumns()}
        </DataTable>
      </div>
    </div>
  );
}

export default InternalCapacityTable;

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
import PageLayout from "../../PageLayout";
import TaskHeader from "../DesignJobs/TaskHeader";

import { saveDesignIntent } from "../../../apis/designIntentApi";
import { useDispatch, useSelector } from "react-redux";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import "../DesignJobs/index.scss";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import CloneJobs from "../DesignJobs/CloneJobs";
import { uploadtoAzureStrContainer } from "../../../apis/uploadAzuerContainer";
import "./index.scss";
import CDHeader from "../DesignJobs/CDHeader";
import { submitConfirmColorDevelopment } from "../../../apis/colorDevelopmentApi";

import IQCDFooterButtons from "../DesignJobs/IQCDFooterButtons";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { totalFileSizeQuotient } from "../../../constants/FileUploadConstant";
import { AzureContainerFail, AzureContainerSuccess } from "../../../Utils/constants";

import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";

const breadcrumb = [{ label: "Confirm Color Development & Print Trial" }];

const headerName = "Confirm Color Development & Print Trial";
const jobName = "Confirm_CD_";

function CCD() {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;

  const [data, setData] = useState(null);
  const [CD, setCD] = useState([]);
  const [formValid, setFormValid] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [submittedDI, setSubmittedDI] = useState([]);
  const [formattedValue, setformattedValue] = useState(0);
  const [fileName, setFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [version, setVersion] = useState("V0");
  const [date, setDate] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  let { page1, page2, pageType, TaskID, ProjectID } = useParams();
  const navigate = useNavigate();

  // const checkReadWriteAccess = true;
  const AzureSubFolder = "CD";
  const location = useLocation().pathname.split("/");
  const _location = useLocation();
  const currentUrl = _location?.pathname;

  // const accessSecurityMatrix = usePriorityNew(location[1]);
  const {
    userDetails: { UserGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup
    }
  }
  const [accessSecurityMatrix, setUserAccess] = useState(false);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);

  const folderStructure = useAzureUploadFolderStructure("CD", version);

  const [FormObjects, setFormObjects] = useState({
    Printer:[],
    Pantone:"",
    Select:false,
    Substrate:"",
    FileDetails:[],
    CD_Approved:false,
    Additional_Info:"",
    Printing_Process:"",
    Print_Trial_Done:false,
    IDDSampleApproved:false,
    Print_Trial_Needed:false,
    IDDSampleLabTestApproved:false,
  });
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dropDownMatch, setDropDownMatch] = useState(true);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [InitialFormObjects, setInitialFormObjects] = useState({
    Printer:[],
    Pantone:"",
    Select:false,
    Substrate:"",
    FileDetails:[],
    CD_Approved:false,
    Additional_Info:"",
    Printing_Process:"",
    Print_Trial_Done:false,
    IDDSampleApproved:false,
    Print_Trial_Needed:false,
    IDDSampleLabTestApproved:false,
  });

  useEffect(()=>{
    const isObjectEqual = JSON.stringify(InitialFormObjects) === JSON.stringify(FormObjects)
    if(!isObjectEqual){
      setPageEdited(true);
    }else{
      setPageEdited(false);
    }
  },[FormObjects])

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  useEffect(()=>{
    setUserAccess(getUserAccessForTask(TaskDetailsData));
    setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
  },[TaskDetailsData?.AccessType])

  useEffect(() => {
    let taskId;
    if (TaskID) {
      taskId = TaskID.split("_")[1];
    }
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData) {
      setCD(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0] ||
        [];

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
      if (data) {
        data?.Version !== "" && setVersion(data.Version);
        data?.Timestamp !== "" &&
          setDate(moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format("DD-MMMM-YYYY"));
      }
    }
  }, [TaskDetailsData]);
  const handleCancel = () => {
    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const handleCancelClick = () =>{
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setPageEdited(false);
    setShowDialog(false);
  }

  const addNewEmptyDesign = () => {
    const newDesignIntent = [
      ...CD,
      {
        Design_Job_ID: CD.length + 1,
        isNew: true,
        Print_Trial_Done: null,
        Tier: "",
        Cluster: "",
        Agency_Reference: "",
        Printer: [],
        Printing_Process: "",
        Design_Job_Name: "",
        Substrate: "",
        Additional_Info: "",
        CD_Approved: null,
        Select: null,
        Print_Trial_Needed: null,
      },
    ];
    setCD(newDesignIntent);
    setUpdated(!updated);
  };

  const addData = (fieldName, index, value, Design_Intent_Name) => {
    let data = CD[index];
    data[fieldName] = value;
    submittedDI.push(data);
    setSubmittedDI(submittedDI);
    checkFormValidity();

    if(value){
      setFormObjects((prev)=>{
        return{
          ...prev,
          [fieldName]:value,
        }
      })
    }
  };

  useEffect(() => {
    checkFormValidity();
  }, [data]);

  const checkFormValidity = () => {
    const validTasks = CD?.filter((task) => {
      return task?.Printing_Process && task?.Substrate && task?.CD_Approved;
    });

    if (validTasks.length > 0) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const onSelectAll = (checked) => {
    CD?.map((task) => {
      if (task?.Event !== "submit") {
        task.Select = checked;
      }
      return task;
    });
    setCD(CD);
    setUpdated(!updated);
  };

  const onFileUpload = (e, FileDetails) => {
    // setPageEdited(true);
    setFileName(e?.files?.[0].name);
    setformattedValue(e?.files?.[0].size);
    setAzureFile(e?.files?.[0]);
    setVersion(FileDetails?.Version);
    setFullName(FileDetails?.Full_Name);
    setEmail(FileDetails?.Email);
    if(e?.files?.[0]?.name){
      setFormObjects((prev)=>{
        return{
          ...prev,
          FileDetails:FileDetails,
        }
      })
    }
  };

  const onFileClear = () => {
    // setPageEdited(true);
    setFileName(null);
    setformattedValue(null);
    setAzureFile(null);
    setFullName("");
    setEmail("");
    setFormObjects((prev)=>{
      return{
        ...prev,
        FileDetails:[],
      }
    })
  };

  const onSubmit = async () => {
    setLoader(true);
    let Timestamp;
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    let formData = {
      caseTypeID: "PG-AAS-Work-ConfirmColorDevelopmentDone",
      content: {
        AWMTaskID: data.Task_ID,
        AWMProjectID: ProjectID,
        DesignJobID: CD[0].Design_Job_ID,
        Size: fileSize,
        Version: version,
        Filename: fileName,
        Filepath: "",
        Timestamp:
          Timestamp ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
            ?.Timestamp,
        CDApproved: CD[0].CD_Approved,
        PrintTrialDone: CD[0].Print_Trial_Done,
        Full_Name: fullName,
        Email: email,
        JobID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
        JobName: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_Name,
        TypeOfFile: fileName?.split(".").pop(),
      },
    };
    let id = data.Task_Key;
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    let azureRes = await uploadtoAzureStrContainer(azureFile, folderStructure);
    if (azureRes) {
      if (toast.current) {
        toast.current.show(AzureContainerSuccess);
      }
    } else {
      if (toast.current) {
        toast.current.show(AzureContainerFail);
      }
    }
    await submitConfirmColorDevelopment(formData, id, headers);
    setLoader(false);

    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const onSaveAsDraft = async () => {
    let submitOnlySelectedData = CD?.map((task) => {
      task.Action = "update";
      if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      if (task?.isNew) {
        task.Design_Job_ID = "";
      }

      task.Event = "draft";
      task.AWM_Project_ID = "A-1000";
      return task;
    });
    let formData = {
      DesignIntentList: submitOnlySelectedData,
    };

    await uploadtoAzureStrContainer(azureFile, folderStructure);
    await saveDesignIntent(formData);
  };

  return (
    <PageLayout>
      {loader || loading || CD === null ? (
        <LoadingOverlay active={loader || loading || CD === null} spinner text="" />
      ) : accessSecurityMatrix ?
        <>
        <CDHeader
          setAddNewDesign={addNewEmptyDesign}
          onSelectAll={onSelectAll}
          breadcrumb={breadcrumb}
          headerName={headerName}
          label="Confirm Color Development & Print Trial"
          disabled={true}
          checkReadWriteAccess={checkReadWriteAccess}
          data={data}
          lastLabel={"CCD"}
          pageEdited={pageEdited}
          setPageEdited={setPageEdited}
          showFilterHeaders={true}
        />
        <div
          className="task-details"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            height: "400px",
          }}
        >
          {<TaskHeader {...data} TaskDetailsData={TaskDetailsData} pageEdited={pageEdited} setPageEdited={setPageEdited} />}
          {/* {data?.Task_Status === "Complete" && (
            <div className="task-completion">This task is already submitted</div>
          )} */}
          {CD &&
            CD.length > 0 &&
            CD.map((item, index) => {
              if (item && item?.Action !== "delete") {
                return (
                  <CloneJobs
                    key={index}
                    {...data}
                    CD={CD}
                    data={data}
                    item={item}
                    AWMProjectID={TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}
                    AWMTaskID={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID}
                    Bu={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
                    Region={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region}
                    hideClearButton={false}
                    index={index}
                    addData={addData}
                    jobName={jobName}
                    formValid={formValid}
                    setFormValid={setFormValid}
                    dropDownMatch={dropDownMatch}
                    setDropDownMatch={setDropDownMatch}
                    setformattedValue={setformattedValue}
                    setAzureFile={setAzureFile}
                    version={version}
                    date={date}
                    pageEdited={pageEdited}
                    setPageEdited={setPageEdited}
                    setFormObjects={setFormObjects}
                    setInitialFormObjects={setInitialFormObjects}
                    checkReadWriteAccess={checkReadWriteAccess}
                    Artwork_Brand={TaskDetailsData?.ArtworkAgilityPage?.Artwork_Brand}
                    Artwork_Category={TaskDetailsData?.ArtworkAgilityPage?.Artwork_Category}
                    azureSubFolder={AzureSubFolder}
                    accessSecurityMatrix={accessSecurityMatrix}
                    onFileUpload={onFileUpload}
                    onFileClear={onFileClear}
                  />
                );
              }
            })}
        </div>
        <IQCDFooterButtons
          handleCancel={handleCancelClick}
          onSaveAsDraft={onSaveAsDraft}
          onSubmit={onSubmit}
          formValid={!formValid}
          checkReadWriteAccess={checkReadWriteAccess}
          bottomFixed={true}
          data={data}
          pageEdited={pageEdited}
          setPageEdited={setPageEdited}
          accessSecurityMatrix={accessSecurityMatrix}
        />
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
      </>
      : <UnAuthorizedComponent nowrapper={true} />
      }
    </PageLayout>
  );
}

export default CCD;

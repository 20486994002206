import { useState, useRef, useEffect } from "react";
import "./index.scss";
import { Button } from "primereact/button";
import moment from "moment";
import enabledUpload from "../../../assets/images/enabled-upload.svg";
import { isFileValid } from "../../../Utils/FileValidation";
import cancel from "../../../assets/images/cancel.svg";
import { changeDateFormat, replaceSpecialChars } from "../../../utils";
import VersionHistory from "../FileVersionHistory/VersionHistory";
import FileList from "./FileList";
import { defaultAllowedFileType, fileTypeErrorMessage } from "../../../constants/FileUploadConstant";


const stateValues = {
    dragActive: false,
    showVersionList: false,
    showFileUploadBox: true,
    isValid: true,
    errorMessage: "",
    fileList: [],
    versionList: [],
};

const FileUploadManager = ({acceptType=defaultAllowedFileType,errorType=fileTypeErrorMessage,...props}) => {
    useEffect(() => {
        if (
            (props?.taskdata?.[0]?.Info && props.taskdata?.[0]?.Info?.File_Name !== "") ||
            (props?.taskdata?.[0] && props.taskdata?.[0]?.File_Name !== "")
        ) {
            const submittedFiles = props.taskdata;
            const versionList = submittedFiles.map((submittedFile) => {
                return {
                    Version: submittedFile?.Info?.version
                        ? submittedFile?.Info?.version
                        : submittedFile.Version,
                    Filename: replaceSpecialChars(submittedFile.File_Name),
                    Owner: submittedFile?.Info ? (
                        <div>
                            {submittedFile?.Info?.Full_Name}
                            <br />
                            {submittedFile?.Info?.Email}
                        </div>
                    ) : (
                        <div>
                            {submittedFile.Full_Name}
                            <br />
                            {submittedFile.Email}
                        </div>
                    ),
                    Date: changeDateFormat(submittedFile.Timestamp),
                    ReadOnlyURL: submittedFile.ReadOnlyURL,
                };
            });

            setState((prevState) => ({
                ...prevState,
                fileList: [props.taskdata[0]],
                versionList: versionList,
            }));
        } else {
            setState((prevState) => ({ ...prevState, fileList: [], versionList: [] }));
        }
    }, [props.taskdata]);

    const [state, setState] = useState(stateValues);

    const inputRef = useRef(null);
    const handleDrag = function (event) {
        event.preventDefault();
        if(!props?.rejected){
            props?.setPageEdited(true);
        }
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setState((prevState) => ({ ...prevState, dragActive: true }));
        } else if (event.type === "dragleave") {
            setState((prev) => ({ ...prev, dragActive: false }));
        }
    };

    const handleDrop = function () {
        props?.setPageEdited(true);
        setState((prevState) => ({ ...prevState, dragActive: false }));
    };
    const dropzoneId = "dragZone";

    useEffect(()=>{
        if(props?.setFormObjects){
            props?.setFormObjects((prev)=>{
                return{
                    ...prev,
                    fileList:state?.fileList
                }
            })
        }
    },[state?.fileList])

    // The code ensures that drag-and-drop functionality is restricted to the element with the specified ID (dragZone). This prevents users from dropping files or dragged items anywhere else on the page. 
    useEffect(() => {
        const handleDragEnter = (e) => {
            if (e.target.id === dropzoneId) {
                e.preventDefault();
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
            }
        };
        const handleDragOver = (e) => {
            if (e.target.id !== dropzoneId) {
                e.preventDefault();
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
            }
        };
        window.addEventListener("dragenter", handleDragEnter);
        window.addEventListener("dragover", handleDragOver);

        // Cleanup function to remove the event listeners
        return () => {
            window.removeEventListener("dragenter", handleDragEnter);
            window.removeEventListener("dragover", handleDragOver);
        };
    }, [dropzoneId]);

    const handleChange = function (event) {
        event.preventDefault();
        props?.setPageEdited(true);
        const _selectedFile = event.target.files[0];

        const [isValid, errorMessage] = isFileValid(_selectedFile, props.accept, props.maxSize, acceptType,errorType);

        setState((prevState) => ({ ...prevState, isValid: isValid, errorMessage: errorMessage }));

        if (isValid) {
            const ownerName = localStorage.getItem("FirstName") + " " + localStorage.getItem("LastName");
            const ownerEmail = `${localStorage.getItem("userId")}@pg.com`;

            const newFile = {
                file: event.target.files[0],
                File_Name: event.target.files[0].name,
                fileType: event.target.files[0].type,
                Full_Name: ownerName,
                Email: ownerEmail,
                Timestamp: moment().utc().format("YYYYMMDD[T]HHmmss.SSS [GMT]"),
                Version:
                    state?.versionList?.[0]?.Filename === ""
                        ? state.versionList.length
                        : state.versionList.length + 1,
            };
            props.onFileUpload(event.target, newFile, props?.fileUploadSection, props?.sequence);
            setState((prevState) => ({ ...prevState, showFileUploadBox: false }));
            setState((prevState) => ({ ...prevState, fileList: [newFile, ...prevState.fileList] }));
            if(props.isAcp){
            props.setSelectedFile(_selectedFile)
            }
        }
    };

    const handleUpdate = function (event, fileListToUpdate) {
        props.setPageEdited(true);
        event.preventDefault();
        const selectedFileUpdate = event.target.files[0];

        const [isValid, errorMessage] = isFileValid(selectedFileUpdate, props.accept, props.maxSize,acceptType,errorType);

        setState((prevState) => ({ ...prevState, isValid: isValid, errorMessage: errorMessage }));

        if (isValid) {
            const updatedFileList = state.fileList.map((fileList) => {
                if (fileListToUpdate === fileList) {
                    const updatedFileListData = {
                        ...fileList,
                        file: event.target.files[0],
                        File_Name: event.target.files[0].name,
                        fileType: event.target.files[0].type,
                        Timestamp: moment().utc().format("YYYYMMDD[T]HHmmss.SSS [GMT]"),
                    };

                    props?.onFileUpload(
                        event.target,
                        updatedFileListData,
                        props?.fileUploadSection,
                        props?.sequence
                    );
                    return updatedFileListData;
                }
                return fileList;
            });
            setState((prevState) => ({
                ...prevState,
                showFileUploadBox: false,
                fileList: [...updatedFileList],
            }));
            if(props.isAcp){
                props.setSelectedFile(selectedFileUpdate);
            }

        } else {
            selectedFileUpdate && onDelete(fileListToUpdate);
        }
    };

    const onUploadButtonClick = () => {
        inputRef.current.click();
    };

    const onDelete = (fileListToRemove) => {
        props.setPageEdited(true);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        const newFileList = state.fileList.filter((fileList) => fileList !== fileListToRemove);
        props.onFileClear(props?.fileUploadSection, fileListToRemove);
        setState((prevState) => ({
            ...prevState,
            showFileUploadBox: true,
            fileList: [...newFileList],
        }));
    };

    const showVersionPopUp = () => {
        setState((prevState) => ({ ...prevState, showVersionList: true }));
    }; 
   
   

    return (
        <div className="file-upload-manager-section">
            {state.showFileUploadBox && props.taskStatus === "In-Progress" && (
                <div className="file-upload-manager-container">
                    <div className="file-upload-section" onDragEnter={handleDrag}>
                        <div className="file-upload-container">
                            <input
                                ref={inputRef}
                                type="file"
                                accept={props.accept}
                                className={state.dragActive ? "file-dragdrop-box" : "input-file-upload"}
                                onChange={handleChange}
                                onDrop={handleDrop}
                                onDragLeave={handleDrag}
                                disabled={!props.checkReadWriteAccess}
                                id={dropzoneId}
                            />
                            <Button
                                className="upload-icon-cta"
                                text
                                aria-label="Upload"
                                onClick={onUploadButtonClick}
                                disabled={!props.checkReadWriteAccess}
                            >
                                <img src={enabledUpload} alt="Upload" />,
                            </Button>
                            <div className="upload-text">
                                <p>
                                    Drop File to Attach or{" "}
                                    <Button
                                        className="upload-text-cta"
                                        label="Browse"
                                        link
                                        onClick={onUploadButtonClick}
                                        disabled={!props.checkReadWriteAccess}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {state.errorMessage && !state.isValid && (
                <div className="error-message-wrapper">
                    <img src={cancel} alt="error icon" />
                    <div className="error-message">{state.errorMessage}</div>
                </div>
            )}
            {state.fileList && state.fileList.length > 0 && state.fileList[0].File_Name !== "" && (
                <FileList
                    fileList={state.fileList}
                    showVersionPopUp={showVersionPopUp}
                    accept={props.accept}
                    subFolder={props.subFolder}
                    handleUpdate={handleUpdate}
                    onDelete={onDelete}
                    toShowVersion={props.toShowVersion}
                    toShowViewOption={props.toShowViewOption}
                />
            )}
            {(state?.fileList?.length === 0 || state?.fileList?.File_Name === "") &&
                props.taskStatus === "Complete" && (
                    <div className="uploaded-files-not-found">No files found</div>
                )}
            <div>
                <VersionHistory
                    showVersionList={state.showVersionList}
                    fileList={state.versionList}
                    onHide={() => setState((prevState) => ({ ...prevState, showVersionList: false }))}
                    subFolder={props.subFolder}
                ></VersionHistory>
            </div>
        </div>
    );
};

export default FileUploadManager;

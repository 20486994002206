import React, { useEffect, useState,useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Row, Col } from "react-bootstrap";
import { Checkbox } from "primereact/checkbox";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import { MultiSelect } from "primereact/multiselect";

import { AutoComplete } from "primereact/autocomplete";
import { useParams } from "react-router-dom";
import { isArray } from "lodash";
import UploadFile from "./UploadFile";
import { useSelector } from "react-redux";
import { textFieldMaxLength } from "../../../constants/index";
import { CharacterLimit } from "../../common/CharacterLimit/CharacterLimit";

import { ToggleButton } from "../../ToggleButton/ToggleButton";
import ConfirmationDialogBox from "../../ConfirmDialog/ConfirmationDialogBox";
import { clearDefinedJobs } from "../../../apis/clearApi";
import { clearPopupMessage, deletePopupMessage } from "../../../constants/DesignJobsConstant";
import "./CloneJobs.scss";
import { SuggestionsMatch } from "../../../Utils/helpers";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";

const CloneJobs = ({
  azureSubFolder,
  index,
  Artwork_Brand,
  Artwork_Category,
  Project_Name,
  handleDelete,
  item,
  data,
  addData,
  addIddData,
  jobName,
  checkReadWriteAccess,
  setformattedValue,
  setAzureFile,
  setFileName,
  IQ,
  date,
  version,
  CD,
  AWMProjectID,
  AWMTaskID,
  Bu,
  Region,
  hideClearButton,
  handleDeleteJob,
  loadOnClearButton,
  onSaveAsDraft,
  onFileUpload,
  onFileClear,
  setDropDownMatch,
  pageEdited,
  setPageEdited,
  setFormObjects,
  setInitialFormObjects,
}) => {
  const {
    Printing_Process,
    Pantone,
    Additional_Info,
    event,
    Select,
    Printer,
    Substrate,
    Design_Job_ID,
    Print_Trial_Needed,
    CD_Approved,
    Print_Trial_Done,
    Design_Job_Status,
    ReworkCounter,
    Taskdata,
  } = item;

  const { DropDownValuesData } = useSelector((state) => state.DropDownValuesReducer);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const IDDSampleApproved = data?.IDDSampleApproved || false;
  const IDDSampleLabTestApproved = data?.IDDSampleLabTestApproved || false;

  // const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  const [PrinterList, setPrinterList] = useState([]);
  const [SubstrateList, setSubstrateList] = useState([]);
  const [PrinterProcessList, setPrinterProcessList] = useState([]);

  const [checked, setChecked] = useState(false);
  const [iddsaChecked, setIddsaChecked] = useState(IDDSampleApproved);
  const [iddsltaChecked, setIddsltaChecked] = useState(IDDSampleLabTestApproved);

  const [printTrailNeeded, setPrintTrailNeeded] = useState(false);
  const [CDConfirmation, setCDConfirmation] = useState(false);
  const [printTrailDone, setPrintTrailDone] = useState(false);
  const [printerProcess, setPrinterProcess] = useState(Printing_Process);
  const [pantone, setPantone] = useState(Pantone || "");
  const [substrateData, setSubstarteData] = useState(Substrate);
  const [printers, setPrinters] = useState([]);

  const [additionalInformation, setAdditionalInfo] = useState(Additional_Info);
  const [filteredItems, setFilteredItems] = useState(null);
  const [taskPageDropDownValues, setTaskPageDropDownValues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [buttonStatus, setButtonStatus] = useState("");
  const [clearChecked, setClearChecked] = useState(false);
  const [width,setWidth]=useState(0);
  const dropdownRef=useRef(null);
  let { TaskID } = useParams();
  const pathName = TaskID.split("_")[0];
  const [showDialog, setShowDialog] = useState(false);
  const [actionType, setActionType] = useState("");
  const [tempIndex, setTempIndex] = useState("");
  const { markDirty, markClean } = useUnsavedChangesWarning();
  
  let Art_Brand = [];
  Artwork_Brand?.forEach((obj) => {
    Art_Brand.push(obj.Brand_Name);
  });
  let Art_Category = [];
  Artwork_Category?.forEach((obj) => {
    Art_Category.push(obj.Category_Name);
  });

  useEffect(()=>{
    if(dropdownRef?.current){
      setWidth(dropdownRef?.current?.offsetWidth)
    }
  },[]);
  // useEffect(() => {
  //   if (User && data) {
  //     if (userInformation?.userid === data?.Assignee) {
  //       setCheckReadWriteAccess(true);
  //     } else {
  //       setCheckReadWriteAccess(false);
  //     }
  //   }
  // }, [User, data]);

  // Function to check if the task is complete or cancelled
  const isTaskCompleteOrCancelled = (taskStatus) => {
    return taskStatus === "Complete" || taskStatus === "Cancelled";
  };

  useEffect(()=>{
    if(Select){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            Select:Select,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            Select:Select,
          }
        })
      }
    }
    if(Pantone){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            Pantone:Pantone,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            Pantone:Pantone,
          }
        })
      }
    }
    if(Substrate){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            Substrate:Substrate,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            Substrate:Substrate,
          }
        })
      }
    }
    if(Additional_Info){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            Additional_Info:Additional_Info,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            Additional_Info:Additional_Info,
          }
        })
      }
    }
    if(Printer?.length){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            Printer:Printer,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            Printer:Printer,
          }
        })
      }
    }
    if(Printing_Process){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            Printing_Process:Printing_Process,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            Printing_Process:Printing_Process,
          }
        })
      }
    }
    if(IDDSampleApproved){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            IDDSampleApproved:IDDSampleApproved,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            IDDSampleApproved:IDDSampleApproved,
          }
        })
      }
    }
    if(IDDSampleLabTestApproved){
      if(setFormObjects){
        setFormObjects((prev)=>{
          return{
            ...prev,
            IDDSampleLabTestApproved:IDDSampleLabTestApproved,
          }
        })
      }
      if(setInitialFormObjects){
        setInitialFormObjects((prev)=>{
          return{
            ...prev,
            IDDSampleLabTestApproved:IDDSampleLabTestApproved,
          }
        })
      }
    }
    if(CD_Approved && setFormObjects){
      setFormObjects((prev)=>{
        return{
          ...prev,
          CD_Approved:CD_Approved,
        }
      })
    }
    if(Print_Trial_Done && setFormObjects){
      setFormObjects((prev)=>{
        return{
          ...prev,
          Print_Trial_Done:Print_Trial_Done,
        }
      })
    }
    if(Print_Trial_Needed && setFormObjects){
      setFormObjects((prev)=>{
        return{
          ...prev,
          Print_Trial_Needed:Print_Trial_Needed,
        }
      })
    }
    if(CD_Approved && setInitialFormObjects){
      setInitialFormObjects((prev)=>{
        return{
          ...prev,
          CD_Approved:CD_Approved,
        }
      })
    }
    if(Print_Trial_Done && setInitialFormObjects){
      setInitialFormObjects((prev)=>{
        return{
          ...prev,
          Print_Trial_Done:Print_Trial_Done,
        }
      })
    }
    if(Print_Trial_Needed && setInitialFormObjects){
      setInitialFormObjects((prev)=>{
        return{
          ...prev,
          Print_Trial_Needed:Print_Trial_Needed,
        }
      })
    }
  },[])

  useEffect(() => {
    let temp = [];
    isArray(Printer) &&
      Printer?.forEach((ptr) => {
        temp.push(ptr.Code);
      });
    setPrinters(temp);
  }, [Printer]);

  useEffect(() => {
    setPantone(Pantone);
  }, [Pantone]);

  useEffect(() => {
    setPrinterProcess(Printing_Process);
  }, [Printing_Process]);

  useEffect(() => {
    setSubstarteData(Substrate);
  }, [Substrate]);

  useEffect(() => {
    setAdditionalInfo(Additional_Info);
  }, [Additional_Info]);

  useEffect(() => {
    if (DropDownValuesData) {
      setTaskPageDropDownValues(DropDownValuesData?.ArtworkAgilityTasksPage || []);
    }
  }, [DropDownValuesData]);

  useEffect(() => {
    if (taskPageDropDownValues !== undefined && taskPageDropDownValues.length !== 0) {
      setPrinterList(taskPageDropDownValues.Artwork_Printer);
      const Artwork_Substrate = taskPageDropDownValues.Artwork_Substrate.reduce(
        (acc, curr) => (acc.push(curr.Substrate_Name), acc),
        []
      );
      setSubstrateList(Artwork_Substrate);
      const Artwork_PrinterProcess = taskPageDropDownValues.Artwork_PrinterProcess.reduce(
        (acc, curr) => (acc.push(curr.PrinterProcess_Name), acc),
        []
      );
      setPrinterProcessList(Artwork_PrinterProcess);
    }
  }, [taskPageDropDownValues]);
  
  let showPage;
  switch (pathName) {
    case "DNPF":
      showPage = "DNPF";
      // Code to execute when pathName is 'DNPF'
      break;
    case "CCD":
      showPage = "CCD";
      // Code to execute when pathName is 'CCD'
      break;
    case "CPT":
      showPage = "CPT";
      // Code to execute when pathName is 'CPT'
      break;
    case "DNIQ":
      showPage = "DNIQ";
      // Code to execute when pathName is 'DNIQ'
      break;
    case "CNIQ":
      showPage = "CNIQ";
      // Code to execute when pathName is 'CNIQ'
      break;
    default:
      // Code to execute when pathName is none of the specified values
      break;
  }

  useEffect(() => {
    setChecked(Select);
    addData("Select", index, Select, di_name);
  }, [Select]);

  useEffect(() => {
    setIddsaChecked(IDDSampleApproved);
  }, [IDDSampleApproved]);

  useEffect(() => {
    setIddsltaChecked(IDDSampleLabTestApproved);
  }, [IDDSampleLabTestApproved]);

  useEffect(() => {
    setCDConfirmation(CD_Approved);
    setPrintTrailDone(Print_Trial_Done);
    setPrintTrailNeeded(Print_Trial_Needed);
  }, [Print_Trial_Needed, CD_Approved, Print_Trial_Done]);

  const handleClearButton = () => {
    setButtonStatus("Clear");
    setVisible(true);
  };

  const handleDeleteButton = (index) => {
    if (Design_Job_Status === "") {
      handleDelete(index);
    } else if (Design_Job_Status === "Awaiting") {
      handleDeleteJob && handleDeleteJob(Design_Job_ID);
      handleDeleteJob && handleDelete(index);
    } else {
      setButtonStatus("Delete");
      setVisible(true);
    }
  };

  const handleContinueClear = async () => {
    const payLoad = {
      AWMProjectID: AWMProjectID,
      AWMTaskID: AWMTaskID,
      BU: Bu,
      Region: Region,
      DesignJobID: Design_Job_ID,
    };

    await clearDefinedJobs(payLoad);

    setChecked(false);
    setPrinters(null);
    setPrinterProcess("");
    setSubstarteData("");
    setPantone("");
    setAdditionalInfo("");
    setPrintTrailNeeded(false);
    setCDConfirmation(false);
    setPrintTrailDone(false);
    setVisible(false);
    setIddsltaChecked(false);
    setClearChecked(true);
    loadOnClearButton && loadOnClearButton();
  };

  const handleJobDelete = async () => {
    handleDeleteJob && handleDeleteJob(Design_Job_ID);
    handleDeleteJob && handleDelete(index);
    setVisible(false);
  };

  const handleClearAndDeleteClick = (type, index) =>{
    if(pageEdited){
      setActionType(type);
      setShowDialog(true);
    }else{
      if(type === "Clear"){
        handleClearButton();
      }else if(type === "Delete"){
        setTempIndex(index);
        handleDeleteButton(index);
      }
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    if(actionType === "Clear"){
      handleClearButton();
    }else if(actionType === "Delete"){
      handleDeleteButton(tempIndex);
    }
    markClean();
    setShowDialog(false);
  }

  const DesignHeader = (di_name) => {
    return (
      <>
        <div
          style={{
            marginLeft: 25,
          }}
          className="font-color"
        >
          {!di_name
            ? jobName === "IQ_"
              ? `${jobName}Pantone_Printer_Brand_Category_Project name_Additional info`
              : `${jobName}_Printer_Printing Process_Substrate_Brand_Category_Project name_Additional info`
            : di_name}
        </div>
        <div className="p-clear-button-align">
          {hideClearButton && (
            <ToggleButton
              title="Clear"
              disabled={isTaskCompleteOrCancelled(data?.Task_Status)}
              onClick={() =>
                Design_Job_Status === "Defined" && ReworkCounter === null && handleClearAndDeleteClick("Clear")
              }
              classname={
                Design_Job_Status === "Defined" && ReworkCounter === null
                  ? "clear-btn active"
                  : "clear-btn inactive"
              }
            />
          )}
          {showPage !== "CNIQ" && showPage !== "CCD" && showPage !== "CPT" &&
            <img
              src={deleteIcon}
              alt="filter logo"
              disabled={isTaskCompleteOrCancelled(data?.Task_Status) || !checkReadWriteAccess}
              onClick={() =>checkReadWriteAccess && handleClearAndDeleteClick("Delete",index)}
              className={`delete-icons`}
            />
          }
        </div>
      </>
    );
  };

  let di_name;

  if (
    printerProcess ||
    pantone ||
    printers ||
    substrateData ||
    printers ||
    Artwork_Category ||
    Artwork_Brand
  ) {
    let selectedPrinter = [];
    printers?.forEach((val) => {
      PrinterList.forEach((pl) => {
        if (pl.Code === val) {
          selectedPrinter.push(pl.Printer);
        }
      });
    });
    di_name =
      jobName +
      (jobName === "IQ_" ? (pantone ? pantone + "_" : "Pantone_") : "") +
      (jobName === "IQ_"
        ? selectedPrinter.length
          ? selectedPrinter.join(", ") + "_"
          : "Printer" + "_"
        : selectedPrinter.length
          ? selectedPrinter.join(", ") + "_"
          : "Printer_") +
      (jobName !== "IQ_"
        ? printerProcess
          ? printerProcess + "_"
          : "Printing Process_"
        : "") +
      (jobName !== "IQ_" ? (substrateData ? substrateData + "_" : "Substrate" + "_") : "") +
      (Artwork_Brand ? Art_Brand.join(", ") : "Brand") +
      "_" +
      (Artwork_Category ? Art_Category.join(", ") : "Category") +
      "_" +
      Project_Name +
      (additionalInformation ?"_"+ additionalInformation : "_Additional Info");
  }
  const searchFilters = (query, printerProcessList, setFilteredItems) => {
    let _filteredItems = [];

    for (let i = 0; i < printerProcessList.length; i++) {
      let item = printerProcessList[i];
      if (item.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredItems(_filteredItems);
  };

  return (
    <div>
      <div className="design-intent-header ">{DesignHeader(di_name)}</div>
      <Row
        style={{
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 25,
          marginTop: 25,
        }}
        className="label-header"
      >
        {(showPage === "DNPF" || showPage === "DNIQ") && (
          <Col sm={1}>
            <label htmlFor="select"> Select</label>
            <div>
              <Checkbox
                onChange={(e) => {
                  !clearChecked && addData("Select", index, e.checked, di_name);
                  setChecked(e.checked);
                  // setPageEdited(true);
                }}
                checked={checked}
                className="margin-right"
                disabled={
                  !checkReadWriteAccess ||
                  (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "") || isTaskCompleteOrCancelled(data?.Task_Status)
                }
              ></Checkbox>
            </div>
          </Col>
        )}
        <Col sm={2}>
          <div ref={dropdownRef}>
            <label htmlFor="cluster">
              {" "}
              Printer
              {showPage === "DNIQ" ||
                showPage === "CNIQ" ||
                showPage === "DNPF" ||
                showPage === "CCD" ||
                showPage === "CPT" ? (
                <sup> *</sup>
              ) : (
                ""
              )}
            </label>
            <MultiSelect
              id="printers"
              value={printers}
              onChange={(e) => {
                e.stopPropagation();

                let selectedPrinter = [];
                e.value?.forEach((val) => {
                  PrinterList?.forEach((pl) => {
                    if (pl.Code === val) {
                      selectedPrinter.push(pl);
                    }
                  });
                });

                !clearChecked && addData("Printer", index, selectedPrinter, di_name);
                setPrinters(e.value);
                // setPageEdited(true);
              }}
              options={
                PrinterList
                  ? PrinterList.map((obj) => ({
                    label: obj.Printer,
                    value: obj.Code,
                  }))
                  : []
              }
              optionLabel="label"
              filter
              aria-describedby="agency-help"
              disabled={
                (!checkReadWriteAccess ||
                  showPage === "CCD" ||
                  showPage === "CPT" ||
                  showPage === "CNIQ" ||
                  (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) || isTaskCompleteOrCancelled(data?.Task_Status)
              }
              placeholder="Select Printer"
              panelStyle={{width:width}}
            />
          </div>
        </Col>
        {showPage !== "DNIQ" && showPage !== "CNIQ" && (
          <Col sm={2} className="gap-col">
            <div ref={dropdownRef}>
              <label htmlFor="agency">
                Printing Process <sup> *</sup>
              </label>
              <AutoComplete
                id="printer"
                value={printerProcess}
                suggestions={filteredItems}
                completeMethod={(e) => searchFilters(e.query, PrinterProcessList, setFilteredItems)}
                forceSelection
                dropdown
                placeholder="Search Printing Process"
                onChange={(e) => {
                  !clearChecked && addData("Printing_Process", index, e.target.value, di_name);
                  setPrinterProcess(e.target.value);
                  // setPageEdited(true);
                  if(e?.value){
                    if(SuggestionsMatch(PrinterProcessList,e?.value)){
                      setDropDownMatch(true);
                    } else {
                      setDropDownMatch(false);
                    }
                  }
                  else{
                    setDropDownMatch(true);
                  }
                }}
                aria-describedby="cluster-help"
                disabled={
                  (!checkReadWriteAccess ||
                    showPage === "CCD" ||
                    showPage === "CPT" ||
                    (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) ||
                    isTaskCompleteOrCancelled(data?.Task_Status)
                }
                panelStyle={{borderRadius:"4px",width:width}}
              />
            </div>
          </Col>
        )}
        {showPage !== "DNIQ" && showPage !== "CNIQ" && (
          <Col sm={2} className="gap-col">
            <div ref={dropdownRef}>
              <label htmlFor="cluster">
                Substrate <sup> *</sup>{" "}
              </label>
              <AutoComplete
                id="substrate"
                value={substrateData}
                suggestions={filteredItems}
                completeMethod={(e) => searchFilters(e.query, SubstrateList, setFilteredItems)}
                forceSelection
                dropdown
                placeholder="Search user"
                onChange={(e) => {
                  !clearChecked && addData("Substrate", index, e.target.value, di_name);
                  setSubstarteData(e.target.value);
                  // setPageEdited(true);
                  if(e?.value){
                    if(SuggestionsMatch(SubstrateList,e?.value)){
                      setDropDownMatch(true);
                    } else {
                      setDropDownMatch(false);
                    }
                  }
                  else{
                    setDropDownMatch(true);
                  }
                }}
                aria-describedby="cluster-help"
                disabled={
                  (!checkReadWriteAccess ||
                    showPage === "CCD" ||
                    showPage === "CPT" ||
                    (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) ||
                    isTaskCompleteOrCancelled(data?.Task_Status)
                }
                panelStyle={{borderRadius:"4px",width:width}}
              />
            </div>
          </Col>
        )}
        {(showPage === "DNIQ" || showPage === "CNIQ") && (
          <Col sm={2} className="gap-col">
            <div>
              <label htmlFor="agency">Pantone</label>
              <div>
                <InputText
                  id="pantone"
                  maxLength={textFieldMaxLength}
                  value={pantone}
                  placeholder="Enter Pantone"
                  onChange={(e) => {
                    !clearChecked && addData("Pantone", index, e.target.value, di_name);
                    setPantone(e.target.value);
                    // setPageEdited(true);
                  }}
                  aria-describedby="pantone-help"
                  className={pantone?.length === 0 ? 'form-control' : pantone?.length >= textFieldMaxLength ? 'form-control':'form-control border-success'}
                  disabled={
                    !checkReadWriteAccess ||
                    (showPage === "CNIQ" && true) ||
                    (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "") ||
                    isTaskCompleteOrCancelled(data?.Task_Status)
                  }
                />
              </div>
              <CharacterLimit field={pantone} maxLength={textFieldMaxLength} />
            </div>
           
          </Col>
        )}
        <Col sm={2} className="gap-col">
          <Row>
            <label htmlFor="additional">Additional Info </label>
            <Col>
              <div>
                <InputText
                  id="additional"
                  maxLength={textFieldMaxLength}
                  value={additionalInformation}
                  onChange={(e) => {
                    const trimmedValue = e?.target?.value.trim();
                    if (trimmedValue || e?.target?.value === "") {
                    !clearChecked && addData("Additional_Info", index, e.target.value, di_name);
                    setAdditionalInfo(e.target.value);
                    // setPageEdited(true);
                  }}}
                  aria-describedby="info-help"
                  className={additionalInformation?.length === 0 ? 'form-control' : additionalInformation?.length >= textFieldMaxLength ? 'form-control':'form-control border-success'}
                  disabled={
                    (!checkReadWriteAccess ||
                      showPage === "CCD" ||
                      showPage === "CPT" ||
                      showPage === "CNIQ" ||
                      isTaskCompleteOrCancelled(data?.Task_Status) ||
                      (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) &&
                    true
                  }
                />
              </div>
              <CharacterLimit field={additionalInformation} maxLength={textFieldMaxLength} />
            </Col>
          </Row>
        </Col>
        {((showPage !== "DNIQ" && showPage !== "CNIQ") ||
          (showPage === "DNPF" && printTrailNeeded)) && (
            <Col sm={2}>
              <div>
                {(showPage === "DNPF" || printTrailNeeded) && (
                  <div className="print-trial">
                    <div>
                      <Checkbox
                        onChange={(e) => {
                          !clearChecked && addData("Print_Trial_Needed", index, e.checked, di_name);
                          setPrintTrailNeeded(e.checked);
                          // setPageEdited(true);
                        }}
                        checked={event === "submit" ? true : printTrailNeeded}
                        disabled={
                          (!checkReadWriteAccess ||
                            showPage === "CCD" ||
                            showPage === "CPT" ||
                            isTaskCompleteOrCancelled(data?.Task_Status) ||
                            (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) &&
                          true
                        }
                        className={
                          (!checkReadWriteAccess ||
                            showPage === "CCD" ||
                            showPage === "CPT" ||
                            isTaskCompleteOrCancelled(data?.Task_Status) ||
                            (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) &&
                          "disabled-text"
                        }
                      ></Checkbox>
                    </div>
                    <span
                      htmlFor="printTrailNeeded"
                      className={
                        (!checkReadWriteAccess ||
                          showPage === "CCD" ||
                          showPage === "CPT" ||
                          isTaskCompleteOrCancelled(data?.Task_Status) ||
                          (Design_Job_Status !== "Awaiting" && Design_Job_Status !== "")) &&
                        "disabled-text label-header-name"
                      }
                    >
                      Print Trial Needed
                    </span>
                  </div>
                )}
                {(showPage === "CCD" || showPage === "CPT") && (
                  <>
                    <div className="print-trial">
                      <div>
                        <Checkbox
                          onChange={(e) => {
                            !clearChecked && addData("CD_Approved", index, e.checked, di_name);
                            setCDConfirmation(e.checked);
                            setPrintTrailDone(false);
                            // setPageEdited(true);
                          }}
                          checked={event === "submit" ? true : CDConfirmation}
                          className="margin-right"
                          disabled={
                            (!checkReadWriteAccess ||
                              showPage === "CPT" ||
                              isTaskCompleteOrCancelled(data?.Task_Status)) &&
                            true
                          }
                        ></Checkbox>
                      </div>

                      <label
                        htmlFor="printTrailNeeded"
                        className={
                          (!checkReadWriteAccess ||
                            showPage === "CPT" ||
                            isTaskCompleteOrCancelled(data?.Task_Status)) &&
                          "disabled-text"
                        }
                      >
                        CD Approved
                      </label>
                    </div>
                    {printTrailNeeded && (
                      <div className="print-trial">
                        <div>
                          <Checkbox
                            onChange={(e) => {
                              !clearChecked && addData("Print_Trial_Done", index, e.checked, di_name);
                              setPrintTrailDone(e.checked);
                              // setPageEdited(true);
                            }}
                            checked={event === "submit" ? true : printTrailDone}
                            className="margin-right"
                            disabled={
                              !checkReadWriteAccess ||
                              showPage === "CCD" ||
                              (showPage === "CPT" && !CDConfirmation) ||
                              isTaskCompleteOrCancelled(data?.Task_Status)
                            }
                          ></Checkbox>
                        </div>

                        <label
                          htmlFor="printTrailDone"
                          className={
                            !checkReadWriteAccess ||
                              showPage === "CCD" ||
                              (showPage === "CPT" && !CDConfirmation) ||
                              isTaskCompleteOrCancelled(data?.Task_Status)
                              ? "disabled-text"
                              : "enabled-text"
                          }
                        >
                          Print Trial Done
                        </label>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
          )}

        {showPage === "CNIQ" && (
          <Col sm={3} className="gap-col">
            <div style={{ display: "flex" }}>
              <Checkbox
                onChange={(e) => {
                  !clearChecked && addIddData && addIddData("IDDSampleApproved", e.checked, di_name);
                  setIddsaChecked(e.checked);
                  if (!e.checked) {
                    !clearChecked && addIddData && addIddData("IDDSampleLabTestApproved", index, false, di_name);
                    setIddsltaChecked(false);
                  }
                }}
                checked={event === "submit" ? true : iddsaChecked}
                className="margin-right"
                disabled={!checkReadWriteAccess || isTaskCompleteOrCancelled(data?.Task_Status)}
              ></Checkbox>
              <label
                htmlFor="iddsa"
                className={
                  (!checkReadWriteAccess || isTaskCompleteOrCancelled(data?.Task_Status)) &&
                  "disabled-text"
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                {" "}
                IDD Sample Approved
              </label>
            </div>
            <div style={{ display: "flex" }}>
              <Checkbox
                onChange={(e) => {
                  !clearChecked && addIddData("IDDSampleLabTestApproved", e.checked, di_name);
                  setIddsltaChecked(e.checked);
                }}
                checked={event === "submit" ? true : iddsltaChecked}
                className="margin-right"
                disabled={
                  !checkReadWriteAccess ||
                  !iddsaChecked ||
                  isTaskCompleteOrCancelled(data?.Task_Status)
                }
              ></Checkbox>
              <label
                htmlFor="iddslta"
                className={
                  (!checkReadWriteAccess || isTaskCompleteOrCancelled(data?.Task_Status)) &&
                  "disabled-text"
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                {" "}
                IDD Sample Lab Test Approved
              </label>
            </div>
          </Col>
        )}

        {(showPage === "CNIQ" || showPage === "CCD" || showPage === "CPT") && (
          <UploadFile
            {...data}
            upload={true}
            setformattedValue={setformattedValue}
            setAzureFile={setAzureFile}
            setFileName={setFileName}
            item={item}
            data={data}
            jobName={jobName}
            designData={showPage === "CNIQ" ? IQ : CD}
            date={date}
            version={version}
            disabled={!checkReadWriteAccess || isTaskCompleteOrCancelled(data?.Task_Status)}
            azureSubFolder={azureSubFolder}
            checkReadWriteAccess={checkReadWriteAccess}
            onFileUpload={onFileUpload}
            onFileClear={onFileClear}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
          />
        )}
      </Row>
      {buttonStatus === "Clear" && visible && (
        <ConfirmationDialogBox
          visible={visible}
          onHide={setVisible}
          message={<>{clearPopupMessage}</>}
          icon="pi"
          handleContinue={() => handleContinueClear()}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}

      {buttonStatus === "Delete" && visible && (
        <ConfirmationDialogBox
          visible={visible}
          onHide={setVisible}
          message={<>{deletePopupMessage}</>}
          icon="pi"
          handleContinue={() => {
            handleJobDelete();
          }}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}

      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </div>
  );
};

export default CloneJobs;

import {
  CD,
  No,
  Yes,
  UpdatePM,
  massUpdate,
  warningMsg,
  CustomizeView,
  ManageCapacity,
  SelectSupplier,
  NoOfActiveTasks,
  AcceptBVEEndDate,
  UploadLongTermPlan,
} from "../constant";
import "./index.scss";
import { Button } from "react-bootstrap";
import ExportData from "../../ExportData";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Form, Row } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useEffect, useState } from "react";
import filter from "../../../assets/images/filter.svg";
import { AcpService } from "../../../service/ACPService";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ProjectService } from "../../../service/PegaService";
import BlueFilterIcon from "../../../assets/images/BlueFilterIcon.svg";
import searchMyProjectsClicked from "../../../assets/images/searchMyProjectsClicked.svg";
import searchMyProjects from "../../../assets/images/searchMyProjects.svg";
import exapandCollapseAll from "../../../assets/images/expandCollapseAll.svg";
import circularRightArrow from "../../../assets/images/arrow_circle_right.svg";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { getSelectedBuList } from "../../../service/DashboardService";
import { updateuserBU } from "../../../store/actions/UserDetailsActions";
import { useNavigate, useLocation } from "react-router-dom";
import { AcpCommonService } from "../../../service/AcpCommonService";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";

const CapacityManagementHeader = ({
  range,
  demand,
  loader,
  headers,
  tabName,
  selected,
  capacity,
  filename,
  isBVEloop,
  suppliers,
  expandAll,
  editedRow,
  setVisible,
  filterFLag,
  bookingData,
  clearFilter,
  selectedRows,
  setExpandAll,
  setEditedRow,
  onSearchClick,
  handleMessClick,
  isDashboardPage,
  isFilterEnabled,
  noOfActiveTasks,
  setAllEditedRows,
  CustomizeViewFlag,
  hideCustomizedView,
  setResetMultiFilter,
  rightSideHeadersOnly,
  setShowCustomizedView,
  setBookingTableFilters,
  customizeViewFields,
  buRegionList,
  userGroup,
  selectedDropdownValue,
  validatedBuList,
  onChildClick,
  formValid,
  setDateChangeValue,
  isCapacityManager
}) => {
  let today = AcpCommonService.getWeekdayDate();
  let twoWeek = AcpCommonService.getDateWeekdays(today);
  let tomorrow = new Date(today);
  twoWeek.setDate(twoWeek.getDate());
  tomorrow.setDate(tomorrow.getDate() + 1);

  twoWeek.setMinutes(0);
  twoWeek.setHours(0);
  twoWeek.setSeconds(0);
  tomorrow.setMinutes(0);
  tomorrow.setHours(0);
  tomorrow.setSeconds(0);
  const navigate = useNavigate();
  const location = useLocation();
  const disPatch = useDispatch();
  const [faValue, setFAValue] = useState("FA");
  const [callAPi, setCallAPi] = useState(false);
  const [faData, setFAData] = useState(["FA", "CIC"]);
  const [supplierData, setSupplierData] = useState([]);
  const [ModifiedData, setModifiedData] = useState([]);
  const [ShowWarning, setShowWarning] = useState(false);
  const [supplierValue, setSupplierValue] = useState("");
  const [tempSelection, setTempSelection] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const NewStartDatesSaved =
    localStorage.getItem("NewStartDatesSaved") &&
    JSON.parse(localStorage.getItem("NewStartDatesSaved"));
  const [TabWiseHeaders, setTabWiseHeaders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(tomorrow);
  const [selectedEndDate, setSelectedEndDate] = useState(twoWeek);
  const [filterSubmitButton, setFilterSubmitButton] = useState(true);
  const { acpBookingCapacityValuesData } = useSelector((state) => state?.AcpBookingReducer);
  const [buRegionValue, setBuRegionValue] = useState(
    !AcpService.isUBN(selectedDropdownValue) && Object.keys(selectedDropdownValue)?.length
      ? selectedDropdownValue
      : ""
  );
  const [isfilterClicked, setIsFilterClicked] = useState(false);
  //initial state for AWM-2907
  const [showDialog, setShowDialog] = useState(false);
  const [type, setType] = useState("");
  const [tempActionType, setTempActionType] = useState("");
  const { markClean } = useUnsavedChangesWarning();

  const query = AcpService.useQuery();
  const { endDateQueryParams, supplierQueryParams, taskTypeQueryParams, startDateQueryParams } =
    AcpService.decodedParams(query);

  const handleClick = () => {
    const newIsClicked = !isClicked;
    setIsClicked(newIsClicked);
    onSearchClick();
    if (onChildClick) {
      onChildClick(newIsClicked);
    }
  };

  const handleClearFilter = () => {
    const newIsClicked = !isFilterEnabled;
    setIsClicked(newIsClicked);
    setIsFilterClicked(newIsClicked);
    clearFilter();
    if (onChildClick) {
      onChildClick(newIsClicked);
    }
  };
  useEffect(() => {
    const type = [],
      bve = [];
    if (acpBookingCapacityValuesData) {
      acpBookingCapacityValuesData?.forEach((data) => {
        type.push(data.TaskType);
        bve.push(data.BVE);
      });
    }
  }, [acpBookingCapacityValuesData]);

  useEffect(() => {
    setAllEditedRows([]);
    setBookingTableFilters &&
      setBookingTableFilters({
        callAPi: callAPi,
        FAValue: faValue,
        Date: startDateQueryParams
          ? new Date(AcpService.convertToIST(startDateQueryParams))
          : selectedDate,
        endDate: endDateQueryParams
          ? new Date(AcpService.convertToIST(endDateQueryParams))
          : selectedEndDate,
        SupplierValue: supplierValue !== "Select Supplier" ? supplierValue : "",
      });
  }, [
    faValue,
    supplierValue,
    selectedDate,
    selectedEndDate,
    callAPi,
    endDateQueryParams,
    startDateQueryParams,
  ]);

  useEffect(() => {
    if (bookingData?.length && !loader) {
      setModifiedData(bookingData);
    }
  }, [bookingData]);

  useEffect(() => {
    if (tabName === "booking") {
      let jsonValue =
        customizeViewFields && customizeViewFields?.length > 0
          ? JSON.parse(customizeViewFields)
          : null;
      const allSelectedData = jsonValue?.freezedColumns?.fieldsData
        ? (jsonValue?.freezedColumns?.fieldsData).concat(jsonValue?.selectedFields?.fieldsData)
        : jsonValue?.selectedFields?.fieldsData;
      let selectedData = allSelectedData || headers;
      const columnsToExclude = ["SelectAll"];
      selectedData = selectedData?.filter((column) => {
        return !columnsToExclude.includes(column);
      });
      setTabWiseHeaders(selectedData);
    } else if (tabName !== "dashboard") {
      setTabWiseHeaders(headers);
    } else if (tabName === "dashboard") {
      const bookingColumnNames = ProjectService.getDashboardColumnNames();
      setTabWiseHeaders(bookingColumnNames);
    }
  }, [tabName, headers]);
  const handleFAChange = (e) => {
    if (NewStartDatesSaved?.length || editedRow?.isSelected) {
      // setShowWarning(true);
      // setEditedRow({});
      setShowDialog(true);
      setType("FAChange");
      setTempSelection(e?.target?.value);
    } else {
      setFAValue(e?.target?.value);
      // AcpCommonService.updateQueryParams("TaskType", e?.target?.value, location, navigate);
    }
    setResetMultiFilter(false);
  };

  const handleSupplierChange = (e) => {
    if (NewStartDatesSaved?.length || editedRow?.isSelected) {
      setShowDialog(true);
      setType("SupplierChange");
      setTempSelection(e?.target?.value);
    } else {
      setSupplierValue(e?.target?.value);
      setResetMultiFilter(false);
    }
    // AcpCommonService.updateQueryParams("Supplier", e?.target?.value, location, navigate);
  };

  const handleDateChange = (e) => {
    const selectedDate = e?.value;
    // AcpCommonService.updateQueryParams(
    //   "StartDate",
    //   AcpService.changeDateFromISTtoUTC(selectedDate),
    //   location,
    //   navigate
    // );

    if (NewStartDatesSaved?.length || editedRow?.isSelected) {
      setShowDialog(true);
      setType("StartDateChange");
      setTempSelection(selectedDate);
    } else {
      setSelectedDate(selectedDate);
      setFilterSubmitButton(false);
      setResetMultiFilter(false);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e?.value;
    // AcpCommonService.updateQueryParams(
    //   "EndDate",
    //   AcpService.changeDateFromISTtoUTC(selectedEndDate),
    //   location,
    //   navigate
    // );
    if (NewStartDatesSaved?.length || editedRow?.isSelected) {
      setShowDialog(true);
      setType("EndDateChange");
      setTempSelection(selectedEndDate);
    } else {
      setSelectedEndDate(selectedEndDate);
      setFilterSubmitButton(false);
      setResetMultiFilter(false);
    }
  };

  const expandCollapseAll = () => {
    setExpandAll(!expandAll);
  };
  const handleDelegateclick = (type) => {
    if (formValid) {
      setShowDialog(true);
      setTempActionType(type);
    } else {
      handleDelegate(type);
    }
  };

  const handleDelegate = (type) => {
    handleMessClick(type);
  };

  useEffect(() => {
    const sortedData = AcpService.sortDataAlphaNumeric(suppliers);
    setSupplierData(sortedData);
  }, [suppliers]);

  useEffect(() => {
    if (taskTypeQueryParams || supplierQueryParams || startDateQueryParams || endDateQueryParams) {
      setFAValue(
        taskTypeQueryParams === "Upload CIC Matrix"
          ? "CIC"
          : taskTypeQueryParams === "Upload CIC"
          ? "CIC"
          : "FA"
      );
      setSupplierValue(supplierQueryParams);
      setSelectedDate(new Date(AcpService.convertToIST(startDateQueryParams)));
      setSelectedEndDate(new Date(AcpService.convertToIST(endDateQueryParams)));
      setBookingTableFilters({
        // callAPi: true,
        SupplierValue: supplierQueryParams,
        Date: AcpService.convertToIST(startDateQueryParams),
        endDate: AcpService.convertToIST(endDateQueryParams),
        FAValue: taskTypeQueryParams === "Upload CIC" ? "CIC" : "FA",
      });
    } else {
      let endDate;
      if (range !== "") {
        endDate = AcpService.addBusinessDays(selectedDate, range);
      } else {
        endDate = twoWeek;
      }
      setSelectedEndDate(endDate);
    }
  }, [range]);

  const hideDialog = () => {
    setShowWarning(false);
  };
  const hideDialogAndRemoveStorage = () => {
    setShowWarning(false);
    setFAValue(tempSelection);
    localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
  };

  const handleApiCall = () => {
    setCallAPi(true);
    setFilterSubmitButton(true);

    setTimeout(() => {
      setCallAPi(false);
    }, 200);
  };

  const onChangeBuRegionName = (value) => {
    setBuRegionValue(value);
  };

  const handleClickApplyBuRegion = () => {
    if (formValid) {
      setShowDialog(true);
      setType("ArrowClickAPiCall");
    } else {
      applyBuRegion();
    }
  };

  const applyBuRegion = () => {
    const selectedBu = getSelectedBuList(validatedBuList, buRegionValue);
    disPatch(updateuserBU({ bu: selectedBu, dropdownValue: buRegionValue }));
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };

  const confirmNavigation = () => {
    if (type === "ArrowClickAPiCall") {
      applyBuRegion();
      setAllEditedRows([]);
      setDateChangeValue([]);
    } else if (type === "FAChange") {
      setEditedRow({});
      setAllEditedRows([]);
      setDateChangeValue([]);
      setFAValue(tempSelection);
      localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
    } else if (
      type === "SupplierChange" ||
      type === "StartDateChange" ||
      type === "EndDateChange"
    ) {
      if (type === "SupplierChange") {
        setSupplierValue(tempSelection);
      } else if (type === "StartDateChange") {
        setSelectedDate(tempSelection);
      } else if (type === "EndDateChange") {
        setSelectedEndDate(tempSelection);
      }
      setEditedRow({});
      setAllEditedRows([]);
      setDateChangeValue([]);
      setResetMultiFilter(false);
      setFilterSubmitButton(false);
      localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
    } else {
      handleDelegate(tempActionType);
      setTempActionType("");
    }
    setType("");
    setShowDialog(false);
  };

  return (
    <div className="actions acp-actions">
      {tabName === "dashboard" && buRegionList?.length && buRegionList?.length > 1 ? (
        <div className="dashboard-container">
          <div className="autocomplete-container">
            <CommonAutocomplete
              suggestions={buRegionList}
              value={buRegionValue}
              optionLabel="buRegionList"
              placeholder="Select BU-Region"
              onChange={(e) => {
                onChangeBuRegionName(e?.target?.value);
              }}
              field="label"
            />
          </div>
          <div
            disabled={!buRegionValue}
            onClick={buRegionValue ? handleClickApplyBuRegion : null}
            className={
              buRegionValue
                ? "circularRightArrow pointer"
                : "circularRightArrow circularRightArrowDisable pointer"
            }
          >
            <img src={circularRightArrow} alt="circularRightArrow" />
          </div>
        </div>
      ) : (
        <></>
      )}
      {!rightSideHeadersOnly ? (
        <Row>
          <Form.Group>
            <div className="acp-booking-table-dropdown">
              <Form.Select
                value={faValue}
                className="select-FA"
                onChange={handleFAChange}
                // placeholder="Select FA"
              >
                {/* <option value="">Select FA</option> */}
                {faData?.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
              <div className="cd-indicator">
                <span>
                  <b>
                    {CD}:({capacity}/{demand})
                  </b>
                </span>
              </div>
              <Form.Select
                value={supplierValue}
                className="select-supplier"
                placeholder="Select Supplier"
                onChange={handleSupplierChange}
              >
                <option value="">{SelectSupplier}</option>
                {supplierData?.map((bve, index) => (
                  <option key={index} value={bve}>
                    {bve}
                  </option>
                ))}
              </Form.Select>
              {!isBVEloop && (
                <>
                  <div className="acp-calendar">
                    <Calendar
                      showIcon={true}
                      dateFormat="d-M-y"
                      // maxDate={twoWeek}
                      value={selectedDate}
                      minDate={tomorrow}
                      disabledDays={[0, 6]}
                      onChange={handleDateChange}
                      locale="en"
                      icon={<CalendarIcon />}
                    />
                  </div>
                  <div className="acp-calendar">
                    <Calendar
                      showIcon={true}
                      dateFormat="d-M-y"
                      // maxDate={twoWeek}
                      disabledDays={[0, 6]}
                      minDate={selectedDate}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      locale="en"
                      icon={<CalendarIcon />}
                    />
                  </div>
                  <div
                    disabled={filterSubmitButton}
                    onClick={!filterSubmitButton ? handleApiCall : null}
                    className={
                      !filterSubmitButton
                        ? "circularRightArrow pointer"
                        : "circularRightArrow circularRightArrowDisable pointer"
                    }
                  >
                    <img src={circularRightArrow} alt="circularRightArrow" />
                  </div>
                </>
              )}
              {isBVEloop && (
                <div style={{ marginTop: 6 }}>
                  {NoOfActiveTasks} - {noOfActiveTasks}{" "}
                </div>
              )}
            </div>
          </Form.Group>
        </Row>
      ) : (
        <Row></Row>
      )}
      {/* <Tooltip target=".expand-collapse" mouseTrack mouseTrackLeft={10} /> */}

      <div className="action-buttons">
        <>
          <img
            alt="filter"
            hidden={filterFLag}
            onClick={() => {
              handleClearFilter();
            }}
            className="header-icons"
            disabled={
              (tabName === "dashboard" &&
                buRegionList?.length &&
                buRegionList?.length > 1 &&
                !AcpService.isUBN(selectedDropdownValue) &&
                !Object.keys(selectedDropdownValue)?.length) ||
              (tabName === "dashboard" && validatedBuList?.length === 0)
            }
            src={isFilterEnabled ? BlueFilterIcon : filter}
          />
          <img
            alt="search"
            src={isClicked ? searchMyProjects : searchMyProjectsClicked}
            onClick={handleClick}
            disabled={
              (tabName === "dashboard" &&
                buRegionList?.length &&
                buRegionList?.length > 1 &&
                !AcpService.isUBN(selectedDropdownValue) &&
                !Object.keys(selectedDropdownValue)?.length) ||
              (tabName === "dashboard" && validatedBuList?.length === 0)
            }
            className="header-icons"
          />
          <img
            className={`expand-collapse ${expandAll ? "nonInvertedIcon" : "invertedIcon"}`}
            data-pr-tooltip="Expand/Collapse All"
            src={exapandCollapseAll}
            onClick={
              (tabName === "dashboard" &&
                buRegionList?.length &&
                buRegionList?.length > 1 &&
                !AcpService.isUBN(selectedDropdownValue) &&
                !Object.keys(selectedDropdownValue)?.length) ||
              (tabName === "dashboard" && validatedBuList?.length === 0)
                ? null
                : expandCollapseAll
            }
            alt="expand"
            disabled={
              (tabName === "dashboard" &&
                buRegionList?.length &&
                buRegionList?.length > 1 &&
                !AcpService.isUBN(selectedDropdownValue) &&
                !Object.keys(selectedDropdownValue)?.length) ||
              (tabName === "dashboard" && validatedBuList?.length === 0)
            }
          ></img>
          <ExportData
            filename={filename}
            allData={ModifiedData}
            selectedRows={selected}
            headers={TabWiseHeaders}
            disabled={
              (tabName === "dashboard" &&
                buRegionList?.length &&
                buRegionList?.length > 1 &&
                !AcpService.isUBN(selectedDropdownValue) &&
                !Object.keys(selectedDropdownValue)?.length) ||
              (tabName === "dashboard" && validatedBuList?.length === 0)
            }
          />
          {hideCustomizedView ? (
            <></>
          ) : (
            <Button
              variant="secondary"
              className="button-layout"
              hidden={CustomizeViewFlag}
              onClick={() => setShowCustomizedView(true)}
            >
              {CustomizeView}
            </Button>
          )}
          <DropdownButton
            title="Actions"
            disabled={
              (tabName === "dashboard" &&
                buRegionList?.length &&
                buRegionList?.length > 1 &&
                !AcpService.isUBN(selectedDropdownValue) &&
                !Object.keys(selectedDropdownValue)?.length &&
                !selectedRows.length) ||
              (tabName === "dashboard" && validatedBuList?.length === 0) ||
              (tabName !== "dashboard" && !selectedRows.length) ||
              !isCapacityManager
            }
            // disabled={!selectedRows.length}
            className="dropdown-button-custom"
            // id={
            //   tabName === "dashboard" ? "" : !selectedRows?.length ? "tasksInActive" : "tasksActive"
            // }
          >
            {isBVEloop ? (
              <Dropdown.Item onClick={() => setVisible(true)}>{AcceptBVEEndDate}</Dropdown.Item>
            ) : isDashboardPage ? (
              <React.Fragment>
                {/* <Dropdown.Item onClick={() => handleDelegateclick("Configuration")}>
                  {Configuration}
                </Dropdown.Item> */}
                <Dropdown.Item
                  disabled={!selectedRows?.length}
                  onClick={() => handleDelegateclick("UpdatePM")}
                >
                  {UpdatePM}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={
                    (tabName === "dashboard" &&
                      buRegionList?.length &&
                      buRegionList?.length > 1 &&
                      !AcpService.isUBN(selectedDropdownValue) &&
                      !Object.keys(selectedDropdownValue)?.length) ||
                    (tabName === "dashboard" && validatedBuList?.length === 0)
                  }
                  onClick={() => handleDelegateclick("ManageCapacity")}
                >
                  {ManageCapacity}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={
                    (tabName === "dashboard" &&
                      buRegionList?.length &&
                      buRegionList?.length > 1 &&
                      !AcpService.isUBN(selectedDropdownValue) &&
                      !Object.keys(selectedDropdownValue)?.length) ||
                    (tabName === "dashboard" && validatedBuList?.length === 0)
                  }
                  onClick={() => handleDelegateclick("UploadLongTermPlan")}
                >
                  {UploadLongTermPlan}
                </Dropdown.Item>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Dropdown.Item
                  disabled={!selectedRows?.length}
                  onClick={() => handleDelegateclick("massUpdate")}
                >
                  {massUpdate}
                </Dropdown.Item>
              </React.Fragment>
            )}
          </DropdownButton>
        </>
      </div>
      {/* <Dialog
        onHide={hideDialog}
        visible={ShowWarning}
        className="warning-dialog"
        header={<p>{warningMsg}</p>}
      >
        <div className="p-fluid popup-detailss">
          <div className="btn-wrapper">
            <button className="btn-no" onClick={hideDialog}>
              {No}
            </button>
            <button className="btn-yes" onClick={hideDialogAndRemoveStorage}>
              {Yes}
            </button>
          </div>
        </div>
      </Dialog> */}

      {/* UnsavedChangesDialog popup*/}
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </div>
  );
};

export default CapacityManagementHeader;

import React, { useEffect, useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import moment from "moment";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import FooterButtons from "../DesignJobs/FooterButtons";
import AddNewDesign from "../DesignJobs/TaskHeader";
import ApproveDesignIntentContent from "../DesignJobs/ApproveDesignIntentContent";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { postSaveDesignIntent } from "../../../apis/uploadSaveAsDraft";
import { submitUploadApproveDesignIntent } from "../../../apis/uploadSubmitAPIs";
import { uploadtoAzureStrContainer } from "../../../apis/uploadAzuerContainer";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { redirectToProjectPlanOrTaskPage } from "../../../utils";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { totalFileSizeQuotient } from "../../../constants/FileUploadConstant";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";

const breadcrumb = [
  { label: "My Tasks", url: "/tasks" },
  { label: "Upload Approved Design Intent" },
];

const UADI = () => {
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [designIntent, setDesignIntent] = useState(null);
  const [formattedValue, setformattedValue] = useState(0);
  const [fileName, setFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [version, setVersion] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  let checkTaskIsComplete = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete";
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location?.pathname;
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const roleName = "DI_";
  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const folderStructure = useAzureUploadFolderStructure("DI", version);
  // 3109 security matrix
  const [accessType, setAccessType] = useState("Write");
  const [accessSecurityMatrix, setUserAccess] = useState(true);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false); const {
   userDetails: { AccessGroup ,UserGroup},
 } = useSelector((state) => state?.UserDetailsReducer);
 const { userInformation } = useSelector((state) => state.UserReducer);

 useEffect(()=>{
  setUserAccess(getUserAccessForTask(TaskDetailsData));
  setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
  setAccessType(TaskDetailsData?.AccessType);
},[TaskDetailsData?.AccessType]);

  // 3109 security matrix
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup
    }
  }
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [FileUploaded, setFileUploaded] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [FormObjects, setFormObjects] = useState({fileList:[]});
  const [InitialFormObjects, setInitialFormObjects] = useState({fileList:[]});

  useEffect(()=>{
    const isObjectEqual = JSON.stringify(InitialFormObjects) === JSON.stringify(FormObjects)
    if(!isObjectEqual && FileUploaded){
      setPageEdited(true);
    }else{
      setPageEdited(false);
      setFileUploaded(false);
    }
  },[FormObjects])

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  useEffect(() => {
      dispatch(getTaskDetails(TaskID, ProjectID,userAccessPayLoad));
      setAzureFileUploadMsg(false);
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0] ||
        [];

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
      if (data) {
        data.Version !== "" && setVersion(data.Version);
        data.Timestamp !== "" &&
          setDate(moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format("DD-MMMM-YYYY"));
      }
    }
  }, [TaskDetailsData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const handleCancelClick = () =>{
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setShowDialog(false);
  }

  const onSaveAsDraft = async () => {
    setLoader(true);
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    const formData = {
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      Size: fileSize === 0 ? "1" : fileSize,
      Version: version,
      Filename:
        fileName !== ""
          ? fileName
          : TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
              ?.File_Name,
      Full_Name: fullName,
      Email: email,
    };
    let azureResponse = await uploadtoAzureStrContainer(azureFile, folderStructure);
    // await dispatch(uploadFileToAzureShare(azureFile));
    if (azureResponse) {
      await postSaveDesignIntent(formData);
      dispatch(getTaskDetails(TaskID, ProjectID,userAccessPayLoad));
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "File Uploaded",
          detail: "Successfully!",
          life: 3000,
        });
      }
      setLoader(false);
      setAzureFileUploadMsg(false);
    } else {
      if (
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "In-Progress" &&
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
          ?.File_Name !== ""
      ) {
        await postSaveDesignIntent(formData);
        dispatch(getTaskDetails(TaskID, ProjectID,userAccessPayLoad));
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "File Uploaded",
            detail: "Successfully!",
            life: 3000,
          });
        }
        setLoader(false);
        setAzureFileUploadMsg(false);
      } else {
        setAzureFileUploadMsg(true);
        setLoader(false);
      }
    }
  };

  const onSubmit = async () => {
    setLoader(true);
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    const formData = {
      caseTypeID: "PG-AAS-Work-UploadApprovedDesignIntent",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
        Size: fileSize === 0 ? "1" : fileSize,
        Version: version,
        Filename:
          fileName !== ""
            ? fileName
            : TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
                ?.FileMetaDataList?.[0]?.File_Name,
        Full_Name: fullName,
        Email: email,
        TypeOfFile: fileName
          ? fileName?.split(".")?.pop()
          : TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name?.split(
              "."
            )?.pop(),
        JobID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
        JobName: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_Name,
      },
    };

    let azureResponse = await uploadtoAzureStrContainer(azureFile, folderStructure);
    if (azureResponse) {
      await submitUploadApproveDesignIntent(formData, id, headers);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "File Uploaded",
          detail: "Successfully!",
          life: 3000,
        });
      }
      setLoader(false);
      setAzureFileUploadMsg(false);
      redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
    } else {
      if (
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "In-Progress" &&
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
          ?.File_Name !== ""
      ) {
        await submitUploadApproveDesignIntent(formData, id, headers);
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "File Uploaded",
            detail: "Successfully!",
            life: 3000,
          });
        }
        setLoader(false);
        setAzureFileUploadMsg(false);
        redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
      } else {
        setAzureFileUploadMsg(true);
        setLoader(false);
      }
    }
  };
  return (
    <PageLayout>
       {loader ?  <LoadingOverlay active={true} spinner text="" />
      : accessSecurityMatrix ?
      <>
      <Toast ref={toast} />
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        label={shortName}
        lastLabel={"UADI"}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        pageEdited={pageEdited}
        setPageEdited={setPageEdited}
        azureFileUploadMsg={azureFileUploadMsg}
        {...data}
      />
      <div className="task-details">
        {
          <AddNewDesign
            {...data}
            checkReadWriteAccess={checkReadWriteAccess}
            TaskDetailsData={TaskDetailsData}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
          />
        }
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="">
          {designIntent && (
            <ApproveDesignIntentContent
              {...designIntent}
              designIntent={designIntent}
              upload={true}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              setFileName={setFileName}
              fileName={fileName}
              item={data}
              roleName={roleName}
              pageEdited={pageEdited}
              setPageEdited={setPageEdited}
              setFormObjects={setFormObjects}
              setFileUploaded={setFileUploaded}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              isTaskComplete={checkTaskIsComplete}
              version={version}
              setVersion={setVersion}
              setFullName={setFullName}
              setEmail={setEmail}
              date={date}
              checkReadWriteAccess={checkReadWriteAccess}
              subFolder="DI"
              BU={BU}
              projectName={projectName}
              {...data}
            />
          )}
        </LoadingOverlay>
        <div style={{ marginLeft: 30 }}>
          {azureFileUploadMsg && (
            <Message
              severity="error"
              text="Failed to upload file via azure storage please connect pulse VPN and retry"
            />
          )}
        </div>
      </div>

      <FooterButtons
        hideSaveButton={true}
        handleCancel={handleCancelClick}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        formValid={
          fileName === "" || TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete"
        }
        {...data}
      /> 
      </>:(
        AccessGroup && AccessGroup.length && <UnAuthorizedComponent nowrapper={true} />
      )}
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </PageLayout>
  );
};
export default UADI;
